import { womenLeagues } from '../constants/womensLeagues';

const checkIfWomenLeague = (league_id) => {
    let temp = false;
    womenLeagues.forEach((league) => {
        if (Number(league_id) === league.id) {
            temp = true;
        }
    });
    return temp;
};

export default checkIfWomenLeague;
