const clearEmptyFields = (obj) => {
    Object.keys(obj).forEach((key) => {
        let value = obj[key];
        if (value === '') {
            delete obj[key];
        }
    });
    return obj;
};

export default clearEmptyFields;
