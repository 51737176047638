import { useTable } from 'react-table';
import { useRef, useEffect } from 'react';
import Loader from '../Loader';
import './Table.scss';
const defaultPropGetter = () => ({});

const Table = ({
    columns,
    noIdColumn = false,
    data,
    tablePagination,
    tableName,
    nextMatchID = null, /// scroll down to the match
    isRowClickable = false,
    getRowProps = defaultPropGetter,
    sortTable = null,
    isLoaded = true,
}) => {
    const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
        columns,
        data,
    });
    const ref = useRef(null);

    useEffect(() => {
        ref.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    }, []);

    return (
        <div className="Table">
            {tableName && (
                <div className="Table-Name">
                    <p>{tableName}</p>
                    {tablePagination}
                </div>
            )}
            <table className="Table-Table" {...getTableProps()}>
                <thead className="Table-Header">
                    <tr className="Header-Container">
                        {headers.map((column, i) => (
                            <th
                                className={
                                    noIdColumn
                                        ? `Table-Header-Cell_B`
                                        : `Table-Header-Cell ${
                                              column.isHidden ? 'hide-cell' : ''
                                          } ${column.isFixed && 'team-cell'} ${
                                              column.isFixedWider && `team-cell_wider`
                                          } ${sortTable && `clickable`} `
                                }
                                key={i}
                                {...column.getHeaderProps()}
                                title={column.title}
                                onClick={() => sortTable && sortTable(column.id)}
                            >
                                {column.render('Header')}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody className="Table-Body" {...getTableBodyProps()}>
                    {isLoaded ? (
                        rows.length > 0 ? (
                            rows.map((row, i) => {
                                prepareRow(row);
                                var tempRow = row.original.deleted_at;
                                return (
                                    <tr
                                        ref={nextMatchID === row.original?.id ? ref : null}
                                        className={`Table-Body-Row ${
                                            tempRow && row.original.deleted_at !== null
                                                ? 'gray-out'
                                                : null
                                        }${
                                            nextMatchID === row.original?.id ? ' golden-skin' : ''
                                        } ${isRowClickable ? 'clickable' : ''} 
                                 
                                `}
                                        key={i}
                                        {...row.getRowProps(getRowProps(row))}
                                    >
                                        {row.cells.map((cell, i) => {
                                            return (
                                                <td
                                                    key={i}
                                                    {...cell.getCellProps()}
                                                    className={
                                                        noIdColumn
                                                            ? `Table-Body-Row-Cell_B`
                                                            : `Table-Body-Row-Cell ${
                                                                  cell.column.isHidden
                                                                      ? 'hide-cell'
                                                                      : ''
                                                              } ${
                                                                  cell.column.isFixed
                                                                      ? `team-cell`
                                                                      : ''
                                                              } ${
                                                                  cell.column.isFixedWider
                                                                      ? `team-cell_wider`
                                                                      : ''
                                                              }`
                                                    }
                                                >
                                                    {cell.render('Cell')}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })
                        ) : null
                    ) : (
                        <Loader theme="dark" size="big" isCentered={true} table={true} />
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default Table;
