import { types } from 'mobx-state-tree';

const Team = types.model('Team', {
    id: types.identifierNumber,
    name: types.maybe(types.string, ''),
    image_url: types.maybeNull(types.string, ''),
    is_owner: types.maybeNull(types.boolean),
    league_id: types.maybeNull(types.number),
    league: types.frozen({
        id: types.number,
        external_id: types.number,
        name: '',
    }),
});

export default Team;
