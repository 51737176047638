import Paths from '../constants/paths';
import Home from '../pages/Home';
import Admins from '../pages/Admins';
import Teams from '../pages/Teams';
import Matches from '../pages/Matches';
import Table from '../pages/Table';
import Stats from '../pages/Stats';
import Modus from '../pages/Modus';
import MatchesTest from '../pages/MatchesTest';
import PreSeason from '../pages/PreSeason';

import stores from '../stores';

export const AuthorizedRoutesConfig = [
    {
        path: Paths.HOME,
        isExact: true,
        component: Home,
        props: { matchesStore: stores.matches, calendarStore: stores.calendar },
    },
    {
        path: Paths.ADMINS,
        isExact: false,
        component: Admins,
        props: { store: stores.admins },
    },
    {
        path: Paths.TEAMS,
        isExact: false,
        component: Teams,
        props: { store: stores.teams },
    },
    {
        path: Paths.MATCHES,
        isExact: false,
        component: Matches,
        props: { store: stores.matches },
    },
    {
        path: Paths.MATCHES_CUP,
        isExact: false,
        component: Matches,
        props: { store: stores.matches },
    },
    {
        path: Paths.TABLE,
        isExact: false,
        component: Table,
        //props: { store: stores.teams },
    },
    {
        path: Paths.STATS,
        isExact: false,
        component: Stats,
        props: { store: stores.stats },
    },
    {
        path: Paths.STATS_CUP,
        isExact: false,
        component: Stats,
        props: { store: stores.stats },
    },
    {
        path: Paths.MODUS,
        isExact: false,
        component: Modus,
        props: { store: stores.matches },
    },
    {
        path: Paths.TEST_MATCHES,
        isExact: false,
        component: MatchesTest,
        props: { store: stores.matches },
    },
    {
        path: Paths.PRESEASON_MATCHES,
        isExact: false,
        component: PreSeason,
        props: { store: stores.matches },
    },
];
