import './ErrorMessages.scss';

export default function ErrorMessages({ messages }) {
    return (
        <div className="ErrorMessages">
            {typeof messages === 'string' ? (
                <p>{messages}</p>
            ) : (
                messages?.map((error) => <p key={error}>{error}</p>)
            )}
        </div>
    );
}
