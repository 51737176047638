import React, { useEffect } from 'react';
import Page from './../../components/Page';
import { observer } from 'mobx-react-lite';
import Paths from '../../constants/paths';
import Matches from '../Matches/Matches';
import { Route } from 'react-router-dom';
import NewMatch from '../Matches/NewMatch';
import Match from '../Matches/Match';

const MatchesTestTab = ({ store }) => {
    useEffect(() => {
        let joinBy = ['league'];
        let fetchAll = true;
        //league_id, add_unknown, joinBy, fetch-all
        store.getTeams(undefined, true, joinBy, fetchAll);
    }, [store]);

    return (
        <Page>
            <Route exact path={Paths.TEST_MATCHES}>
                <Matches store={store} isTest={true} />
            </Route>
            <Route exact path={Paths.TEST_MATCH}>
                <Match store={store} isTest={true} />
            </Route>
            <Route exact path={Paths.TEST_MATCHES_NEW}>
                <NewMatch store={store} isTest={true} showLeagues={true} />
            </Route>
        </Page>
    );
};

export default observer(MatchesTestTab);
