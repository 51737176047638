const Paths = {
    LOGIN: '/login',
    HOME: '/home',

    ADMINS: '/admins',
    ADMIN: '/admins/:admin_id',
    NEW_ADMIN: '/admins/admin/new',

    TEAMS: '/:league_id/teams',
    TEAM: '/:league_id/teams/:team_id',
    NEW_TEAM: '/:league_id/teams/new',

    TEAM_PLAYER: '/:league_id/teams/:team_id/players/:player_id',
    TEAM_NEW_PLAYER: '/:league_id/teams/:team_id/players/new_player',

    MATCHES: '/:league_id/matches',
    MATCH: '/:league_id/matches/:match_id',
    NEW_MATCH: '/:league_id/matches/match/new',

    MATCHES_CUP: '/:league_id/matches_cup',
    MATCH_CUP: '/:league_id/matches_cup/:match_id',
    NEW_MATCH_CUP: '/:league_id/matches_cup/new',

    TEST_MATCHES: '/:tournament_id/:league_id/test-matches',
    TEST_MATCHES_NEW: '/:tournament_id/:league_id/test-matches/match/new',
    TEST_MATCH: '/:tournament_id/:league_id/test-matches/:match_id',

    PRESEASON_MATCHES: '/:preseason_id/:league_id/preseason-matches',
    PRESEASON_MATCHES_NEW: '/:preseason_id/:league_id/preseason-matches/match/new',
    PRESEASON_MATCH: '/:preseason_id/:league_id/preseason-matches/:match_id',

    MODUS: '/modus',

    MATCH_STATISTICS: '/match-statistics',

    TABLE: '/:league_id/table',
    TABLE_CUP: '/:league_id/table_cup',

    STATS: '/:league_id/stats',
    STATS_CUP: '/:league_id/stats_cup',
};

export default Paths;
