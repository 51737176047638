import React from 'react';
import Page from './../../components/Page';
import { observer } from 'mobx-react-lite';
import { Route } from 'react-router-dom';
import Paths from '../../constants/paths';
import StatsTabs from './StatsTabs';

const StatsTab = ({ store }) => {
    return (
        <Page>
            <Route exact path={Paths.STATS}>
                <StatsTabs store={store} />
            </Route>
            <Route exact path={Paths.STATS_CUP}>
                <StatsTabs store={store} />
            </Route>
        </Page>
    );
};

export default observer(StatsTab);
