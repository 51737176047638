import api from './apiLibrary';

const API = {
    getGames(params) {
        return api.get('/api/admin/fan-game', params);
    },
    getGame(id, params) {
        return api.get(`/api/admin/fan-game/${id}`, params);
    },
    createSeasonalMoodGame(id, data) {
        return api.post(`/api/admin/fan-game/seasonal-mood/${id}`, data);
    },
    editGame(id, data) {
        return api.put(`/api/admin/fan-game/${id}`, data);
    },
    controlGame(id, controlOption) {
        return api.patch(`/api/admin/fan-game/${id}/${controlOption}`);
    },
    setFirstScorerResult(id, data) {
        return api.post(`/api/admin/fan-game/${id}/set/first-scorer`, data);
    },
    getUsersSeasonalMoodStats(gameId, userId) {
        return api.get(`/api/admin/fan-game/${gameId}/statistics/user/${userId}?isToday=false`);
    },
    getRewards(game_id, params) {
        return api.get(`/api/admin/fan-game/${game_id}/reward`, params);
    },
    getWinnersFromFanGameID(fan_game_id) {
        return api.get(`/api/admin/fan-game/${fan_game_id}/rewards/winners`);
    },
    getWinners(reward_id, params) {
        return api.get(`/api/admin/reward/${reward_id}/users`, params);
    },
};

export default API;
