import { Route, useParams } from 'react-router-dom';
import Page from './../../components/Page';
import Teams from './Teams';
import Team from './Team';
import Player from './Player';
import NewTeam from './NewTeam';
import NewPlayer from './NewPlayer';
import Paths from '../../constants/paths';
import ErrorMessages from '../../components/ErrorMessages.js';
import TabSwitcher from '../../components/TabSwitcher';

import { observer } from 'mobx-react-lite';
import leagues from '../../constants/leagues.js';
import { useEffect } from 'react';
import translations from '../../translations/de.js';
import { womenLeagues } from '../../constants/womensLeagues.js';

const TeamsTab = ({ store }) => {
    const { league_id } = useParams();
    useEffect(() => {
        return () => {
            store.resetTeams();
        };
    }, [store]);

    const isWomenLeague = (leagueId) => {
        const womenLeague = leagues.find((league) => league.value === 325);
        return Number(leagueId) === womenLeague?.value;
    };

    return (
        <Page>
            <div style={{ marginLeft: '2.5%' }}>
                <ErrorMessages messages={store.error} />
            </div>
            <Route exact path={Paths.TEAMS}>
                {isWomenLeague(league_id) ? (
                    <>
                        <div className="Table-Woman_League-Name">
                            <p>{translations.teams}</p>
                        </div>
                        <TabSwitcher initialTabName={womenLeagues[0].name}>
                            <TabSwitcher.Tabs>
                                {womenLeagues.map((swhl) => {
                                    return (
                                        <TabSwitcher.TabPill
                                            tabName={swhl.name}
                                            key={`tabName-${swhl.name}`}
                                        />
                                    );
                                })}
                            </TabSwitcher.Tabs>
                            {womenLeagues.map((swhl) => {
                                return (
                                    <TabSwitcher.TabPanel
                                        activeWhenTabName={swhl.name}
                                        key={`tabValue-${swhl.id}`}
                                    >
                                        <Teams
                                            store={store}
                                            isWomenLeague={true}
                                            swhl_id={swhl.id}
                                        />
                                    </TabSwitcher.TabPanel>
                                );
                            })}
                        </TabSwitcher>
                    </>
                ) : (
                    <Teams store={store} />
                )}
            </Route>
            <Route exact path={Paths.TEAM}>
                <Team store={store} />
            </Route>
            <Route exact path={Paths.TEAM_PLAYER}>
                <Player store={store} />
            </Route>
            <Route exact path={Paths.NEW_TEAM}>
                <NewTeam store={store} />
            </Route>
            <Route exact path={Paths.TEAM_NEW_PLAYER}>
                <NewPlayer store={store} />
            </Route>
        </Page>
    );
};

export default observer(TeamsTab);
