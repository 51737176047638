import Form from './Form';
import './Login.scss';
import Logo from './../../assets/images/logo/evz-logo.svg';
import translations from '../../translations/de';

const Login = ({ adminStore }) => {
    return (
        <div className="Login">
            <div className="Login-Container">
                <div className="logo">
                    <img src={Logo} alt="EVZ Logo" />
                </div>
                <h2>{translations.app_name}</h2>
                <Form adminStore={adminStore} />
            </div>
        </div>
    );
};

export default Login;
