import errorMessages from '../configs/errorcodes';

export const getErrorMessage = (arrayErrors) => {
    let formattedErrors = [];
    arrayErrors.forEach((errorId) => {
        if (errorId && errorMessages[errorId]) {
            formattedErrors.push(errorMessages[errorId]);
        } else {
            formattedErrors.push(errorId);
        }
    });

    if (formattedErrors.length) {
        return formattedErrors;
    }
    return false;
};

const requsetErrorMessage = (errors, defaultMessage = 'Ooops! Something went wrong.') => {
    let arrayErrors = errors;

    if (!arrayErrors) {
        return '';
    }

    if (!Array.isArray(errors)) {
        arrayErrors = [errors];
    }

    return arrayErrors.length ? getErrorMessage(arrayErrors) || defaultMessage : defaultMessage;
};

export default requsetErrorMessage;
