import React, { useEffect, useState, useRef } from 'react';
import Table from '../../components/Table';
import Button from '../../components/Button';
import { observer } from 'mobx-react-lite';
import { useHistory, useParams } from 'react-router-dom';
import Modal from '../../components/Modal';
import useModal from '../../customHooks/useModal';
import { FaSearch, FaTrash } from 'react-icons/fa';
import translations from '../../translations/de';
import Loader from '../../components/Loader';
import Select from '../../components/Select';
import ItemImage from '../../components/ItemImage';
import { FaPlus } from 'react-icons/fa';

import { useSeasons } from '../../context/SeasonContext';

const options = [
    { value: 'all', label: translations.all },
    { value: process.env.REACT_APP_TEAM_HOME, label: translations.team_home },
    { value: process.env.REACT_APP_TEAM_NAME, label: translations.team_name },
];

const Matches = ({
    store,
    isCupGames = false,
    phase_id = null,
    isTest = false,
    isPreseason = false,
}) => {
    const history = useHistory();
    const modalRef = useRef(null);
    const [modal, setModal] = useModal(false, modalRef);
    const [matchID, setMatchID] = useState(null);
    const [filter, setFilter] = useState(
        sessionStorage.getItem('match_filter') ? sessionStorage.getItem('match_filter') : 'evz',
    );
    const { seasons, currentSeason } = useSeasons();
    const [season, setSeason] = useState(
        sessionStorage.getItem('selected_season') || currentSeason,
    );
    const { league_id, tournament_id, preseason_id } = useParams();

    var nextMatchID = null;
    const currentDate = new Date();

    const handleMatch = (id) => {
        store.selectMatch(id);
        history.push(
            isCupGames
                ? `/${league_id}/matches_cup/${store.selectedMatch.id}`
                : isTest
                ? `/${tournament_id}/${league_id}/test-matches/${store.selectedMatch.id}`
                : isPreseason
                ? `/${preseason_id}/${league_id}/preseason-matches/${store.selectedMatch.id}`
                : `/${league_id}/matches/${store.selectedMatch.id}`,
        );
    };

    const handleSeasonSelect = (value) => {
        sessionStorage.setItem('selected_season', value);
        setSeason(value);
    };

    useEffect(() => {
        sessionStorage.setItem('match_filter', filter);

        filter &&
            store.getMatches(filter, tournament_id, phase_id, league_id, season, preseason_id);
    }, [store, filter, league_id, phase_id, tournament_id, season, isTest, preseason_id]);

    //  scroll to the game with today's date
    nextMatchID = store.findNextMatch();

    let t = seasons?.find((sea) => Number(sea.value) === Number(season));

    return (
        <>
            <div style={{ width: '100%' }}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Button
                        label={
                            isTest
                                ? translations.test_match
                                : isPreseason
                                ? translations.pre_season + ' ' + translations.match
                                : translations.match
                        }
                        onClick={() => {
                            store.selectMatch(undefined);
                            history.push({
                                pathname: isTest
                                    ? `/${tournament_id}/${league_id}/test-matches/match/new`
                                    : isPreseason
                                    ? `/${preseason_id}/${league_id}/preseason-matches/match/new`
                                    : `/${league_id}/matches/match/new`,
                                state: {
                                    league_id: league_id,
                                    isTest: isTest,
                                    preseason_id: preseason_id,
                                },
                            });
                        }}
                    >
                        <FaPlus style={{ marginRight: '5px' }} />
                    </Button>
                    <div
                        style={{
                            marginLeft: '20px',
                            width: '220px',
                            height: '40px',
                        }}
                    >
                        <Select
                            options={options}
                            isSearchable={false}
                            defaultValue={options.find((option) => option.value === filter)}
                            onChange={(e) => setFilter(e.value)}
                        />
                    </div>
                    <div
                        style={{
                            marginLeft: '20px',
                            width: '220px',
                            height: '40px',
                        }}
                    >
                        {' '}
                        {seasons?.length > 0 && (
                            <Select
                                value={t}
                                options={seasons}
                                isSearchable={false}
                                onChange={(e) => handleSeasonSelect(e.value)}
                            />
                        )}
                    </div>
                </div>
                {store.dataLoaded ? (
                    <>
                        {store.matches && store.matches.length !== 0 ? (
                            <>
                                <Table
                                    tableName={
                                        isTest
                                            ? translations.test_match
                                            : isPreseason
                                            ? translations.pre_season
                                            : translations.matches
                                    }
                                    nextMatchID={nextMatchID}
                                    columns={[
                                        {
                                            Header: 'ID',
                                            accessor: 'id',
                                            isFixedWider: true,
                                            Cell: ({ value }) => <div>{value}</div>,
                                        },
                                        {
                                            Header: translations.home_team,
                                            accessor: 'home_team',
                                            Cell: ({ value }) => (
                                                <div>
                                                    <ItemImage
                                                        imgSrc={value}
                                                        alt="answer"
                                                        listView={true}
                                                        isTeam={true}
                                                    />
                                                </div>
                                            ),
                                        },
                                        {
                                            Header: translations.away_team,
                                            accessor: 'away_team',
                                            Cell: ({ value }) => (
                                                <div>
                                                    <ItemImage
                                                        imgSrc={value}
                                                        alt="answer"
                                                        isTeam={true}
                                                        listView={true}
                                                    />
                                                </div>
                                            ),
                                        },
                                        {
                                            Header: translations.date,
                                            accessor: 'date',
                                            Cell: ({ row }) => (
                                                <div>{store.getMatchDate(row.cells[0].value)}</div>
                                            ),
                                        },
                                        {
                                            Header: translations.time,
                                            accessor: 'time',
                                            Cell: ({ row }) => (
                                                <div>{store.getMatchTime(row.cells[0].value)}</div>
                                            ),
                                        },
                                        {
                                            Header: translations.score,
                                            accessor: 'score',
                                            Cell: ({ value }) => <div>{value}</div>,
                                        },
                                        {
                                            Header: '',
                                            accessor: 'action',
                                            Cell: ({ row }) => (
                                                <div>
                                                    <Button
                                                        onClick={() => {
                                                            handleMatch(row.cells[0].value);
                                                        }}
                                                        title={translations.show}
                                                    >
                                                        <FaSearch />
                                                    </Button>
                                                    <Button
                                                        title={translations.delete}
                                                        onClick={() => {
                                                            setMatchID(row.cells[0].value);
                                                            setModal(true);
                                                        }}
                                                    >
                                                        <FaTrash />
                                                    </Button>
                                                </div>
                                            ),
                                        },
                                    ]}
                                    data={store.matches.map(
                                        ({
                                            id,
                                            home_team,
                                            away_team,
                                            away_score,
                                            home_score,
                                            begin_date,
                                        }) => {
                                            return {
                                                id,
                                                home_team: home_team.image_url,
                                                away_team: away_team.image_url,
                                                score:
                                                    new Date(begin_date) > currentDate
                                                        ? ''
                                                        : `${home_score} : ${away_score}`,
                                            };
                                        },
                                    )}
                                />
                            </>
                        ) : (
                            <div className="no-data">No Data Yet...</div>
                        )}
                    </>
                ) : (
                    <Loader size="big" theme="dark" isCentered={true} />
                )}

                {modal && (
                    <Modal
                        title="Delete Match"
                        ref={modalRef}
                        onClose={() => setModal(false)}
                        maxWidth="400px"
                    >
                        <p style={{ color: '#333', marginBottom: '20px' }}>
                            Are you sure you want to delete this match?
                        </p>
                        <div className="Modal-Controls">
                            <Button
                                label="Yes"
                                onClick={() => {
                                    store.deleteMatch(matchID);
                                    setModal(false);
                                }}
                                style={{ width: '80px' }}
                            />
                            <Button
                                label="No"
                                onClick={() => setModal(false)}
                                style={{ width: '80px', marginLeft: '20px' }}
                            />
                        </div>
                    </Modal>
                )}
            </div>
        </>
    );
};

export default observer(Matches);
