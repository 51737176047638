import { types, applySnapshot } from 'mobx-state-tree';

const Player = types
    .model('Player', {
        id: types.identifierNumber,
        licence_nr: types.maybe(types.number),
        first_name: types.optional(types.string, ''),
        last_name: types.optional(types.string, ''),
        team_id: types.optional(types.number, 0),
        image_url: types.maybeNull(types.string, ''),
        //external_player_id: types.maybeNull(types.number),
        position: types.optional(types.string, ''),
        default_jersey_number: types.maybeNull(types.number),
        birthday: types.maybeNull(types.string),
    })
    .actions((self) => ({
        editPlayer(edited) {
            applySnapshot(self, { ...self, ...edited });
        },
    }));

export default Player;
