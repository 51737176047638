import { types } from 'mobx-state-tree';

const Drawer = types
    .model('Drawer', {
        isDrawerVisible: types.optional(types.boolean, true),
    })
    .actions((self) => ({
        toggleDrawer() {
            self.isDrawerVisible = !self.isDrawerVisible;
        },
    }));

export default Drawer;
