import { types, applySnapshot } from 'mobx-state-tree';

const Admin = types
    .model('Admin', {
        email: types.optional(types.string, ''),
        password: types.maybe(types.string),
        id: types.identifierNumber,
        first_name: types.maybeNull(types.string),
        last_name: types.maybeNull(types.string),
        phone: types.maybeNull(types.string),
        roles: types.maybeNull(types.array(types.string)),
    })
    .actions((self) => ({
        editAdmin(edited) {
            applySnapshot(self, { ...self, ...edited });
        },
    }));

export default Admin;
