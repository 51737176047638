import React, { useRef, forwardRef, useState } from 'react';
import { FaUpload, FaCloud } from 'react-icons/fa';
import './InputFile.scss';
import Modal from './../Modal';
import useModal from '../../customHooks/useModal';
import S3Item from './S3Item';
import Button from '../Button';
import translations from '../../translations/de';
import API from '../../api/users';

const InputFile = forwardRef(
    (
        {
            label = '',
            error = false,
            resetValue,
            disabled,
            pathToCloudFolder,
            readOnly,
            preferredFileSize = translations.imageSizeLabel,
            ...restProps
        },
        ref,
    ) => {
        const inputFileRef = useRef();
        const modalRef = useRef(null);
        const [modal, setModal] = useModal(false, modalRef);
        const [uploads, setUploads] = useState(null);
        const [loading, setLoading] = useState(false);
        const handleUpload = () => {
            if (inputFileRef.current.files[0]) {
                const formData = new FormData();
                formData.append('file', inputFileRef.current.files[0]);

                API.uploadFile(formData, pathToCloudFolder)
                    .then((res) => {
                        resetValue(res.data.url);
                        setLoading(false);
                    })
                    .catch((e) => {
                        console.log(e);
                        setLoading(false);
                    });
            }
        };

        const getFromCloud = () => {
            API.getFiles(pathToCloudFolder)
                .then((res) => {
                    setUploads(res.data);
                    setModal(true);
                })
                .catch((e) => console.log('error', e));
        };
        return (
            <>
                <div className="Input">
                    {label && (
                        <div className="label">
                            <span>{label} </span>
                        </div>
                    )}
                    <div className="Input-File">
                        <input
                            ref={ref}
                            disabled={disabled}
                            readOnly={readOnly}
                            type="text"
                            {...restProps}
                        />

                        <input
                            ref={inputFileRef}
                            type="file"
                            accept="image/*"
                            style={{ display: 'none' }}
                            onChange={handleUpload}
                        />
                        {!disabled && !readOnly && (
                            <div className="Input-File-Controls">
                                <Button
                                    title="Upload Local Files"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        inputFileRef.current.click();
                                    }}
                                    disabled={disabled}
                                    loading={loading}
                                >
                                    <FaUpload />
                                </Button>
                                <Button
                                    title="Uploaded On Cloud"
                                    disabled={disabled}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        getFromCloud();
                                    }}
                                >
                                    <FaCloud />
                                </Button>
                            </div>
                        )}
                    </div>
                    {!disabled && preferredFileSize && (
                        <div className="preferred-format">{preferredFileSize}</div>
                    )}
                    <span className="error-msg">{error && <p>{translations.required}</p>}</span>
                </div>
                {modal && (
                    <Modal
                        title="AWS S3 uploads"
                        ref={modalRef}
                        onClose={() => setModal(false)}
                        maxWidth="900px"
                    >
                        <div className="S3-Itmes">
                            {uploads &&
                                uploads.length !== 0 &&
                                uploads.map((item) =>
                                    item ? (
                                        <S3Item
                                            key={item.key}
                                            path={item.url}
                                            onClick={(source) => {
                                                resetValue(source);
                                                setModal(false);
                                            }}
                                        />
                                    ) : null,
                                )}
                        </div>
                    </Modal>
                )}
            </>
        );
    },
);

export default InputFile;
