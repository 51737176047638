import Paths from '../constants/paths';
import drawerSections from '../constants/drawerSections';

const adminAccessPaths = (accountRoles = []) => {
    const routes = [];
    if (accountRoles.find((role) => role === 'ADMIN')) {
        Object.values(Paths).forEach((path) => routes.push(path));
    }
    if (accountRoles.find((role) => role === 'USER')) {
        routes.push(
            Paths.HOME,
            Paths.TEAMS,
            Paths.TEAM,
            Paths.NEW_TEAM,
            Paths.MATCHES,
            Paths.MATCH,
            Paths.NEW_MATCH,
            Paths.TEAM_PLAYER,
            Paths.TEAM_NEW_PLAYER,
            Paths.MATCH_STATISTICS,
            Paths.TABLE,
            Paths.STATS,
        );
    }
    const uniqueRoutes = [...new Set(routes)];
    return uniqueRoutes;
};

const adminAccessSections = (accountRoles = []) => {
    const sections = [];
    if (accountRoles.find((role) => role === 'ADMIN')) {
        Object.values(drawerSections).forEach((section) => sections.push(section));
    }
    if (accountRoles.find((role) => role === 'USER')) {
        Object.values(drawerSections).forEach(
            (section) => section !== 'ADMIN' && sections.push(section),
        );
    }
    const uniqueSections = [...new Set(sections)];
    return uniqueSections;
};

export { adminAccessPaths, adminAccessSections };
