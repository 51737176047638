import Input from '../../components/Input';
import translateLabel from '../../helpers/translateLabel';
import InputFile from '../../components/InputFile/index.js';
import translations from '../../translations/de';
import InputEmpty from '../../components/InputEmpty';
import Select from '../../components/Select';

export default function getTeamFormInput({
    name,
    register,
    error,
    disabled,
    control,
    index,
    resetValue,
    leagues = [],
}) {
    if (name === 'image_url') {
        return (
            <InputFile
                key={index}
                preferredFileSize={translations.avatarSizeLabel}
                label={translateLabel(name)}
                name={name}
                {...register(name, {
                    required: true,
                })}
                error={error}
                disabled={disabled}
                resetValue={(val) => resetValue(name, val)}
                pathToCloudFolder=""
            />
        );
    } else if (name === 'league_id') {
        return (
            <Select
                key={index}
                label={translateLabel(name.replace('_id', ''))}
                name={name}
                options={leagues}
                control={control}
                error={error}
                disabled={disabled}
                isRequired={true}
            />
        );
    } else if (name === 'dummy') {
        return <InputEmpty key={index} />;
    } else {
        return (
            <Input
                key={index}
                label={translateLabel(name)}
                name={name}
                type="text"
                {...register(name, {
                    required: true,
                })}
                error={error}
                disabled={disabled}
            />
        );
    }
}
