import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Item from '../../components/Item';
import clearObjProps from '../../helpers/clearObjProps';
import { useParams } from 'react-router-dom';
import translations from '../../translations/de';
import Paths from '../../constants/paths';
import getFormInput from './getFormInput';

const Admin = ({ store }) => {
    const { admin_id } = useParams();
    const [edit, setEdit] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const adminModel = clearObjProps({ ...store.selectedAdmin }, ['id']);

    useEffect(() => {
        store.getAdmin(admin_id);
        return () => {
            store.selectAdmin(undefined);
        };
    }, [store, admin_id]);

    const onSubmitHandler = (e) => {
        error && setError(null);
        setLoading(true);
        store
            .editAdmin({
                ...e,
            })
            .then(() => {
                setEdit(false);
                setLoading(false);
            })
            .catch((err) => {
                setError(err);
                setLoading(false);
            });
    };

    return store.selectedAdmin ? (
        <>
            <Item
                links={[{ path: Paths.ADMINS, name: translations.admin_users }]}
                name={store?.selectedAdmin?.email}
                id={`[ admin_ID: ${store.selectedAdmin.id} ]`}
                itemModel={adminModel}
                edit={edit}
                onSubmit={onSubmitHandler}
                onEdit={() => setEdit(true)}
                onCancel={() => {
                    error && setError(null);
                    setEdit(false);
                }}
                error={error}
                loading={loading}
                determineInputType={({ control, name, register, error, disabled, index }) =>
                    getFormInput({
                        name,
                        register,
                        control,
                        error,
                        disabled,
                        index,
                    })
                }
            ></Item>
        </>
    ) : null;
};

export default observer(Admin);
