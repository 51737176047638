const calculateStreak = (streak) => {
    let streakArray = streak.split(',');

    const btnStyle = {
        width: '15px',
        height: '15px',
        borderRadius: '50%',
        border: 'none',
        cursor: 'default',
    };

    const streakElements = streakArray.map((game, index) => {
        switch (game) {
            case 'W':
                return (
                    <button
                        key={index}
                        style={{ ...btnStyle, backgroundColor: 'rgb(23, 181, 150)' }}
                        title="W"
                    />
                );
            case 'OW':
                return (
                    <button
                        key={index}
                        style={{ ...btnStyle, backgroundColor: 'rgb(13, 101, 84)' }}
                        title="OW"
                    />
                );
            case 'L':
                return (
                    <button
                        key={index}
                        style={{
                            ...btnStyle,
                            backgroundColor: 'rgb(220, 0, 76)',
                        }}
                        title="L"
                    />
                );
            case 'SW':
                return (
                    <button
                        key={index}
                        style={{
                            ...btnStyle,
                            backgroundColor: 'rgb(13, 101, 84)',
                        }}
                        title="SW"
                    />
                );
            case 'OL':
                return (
                    <button
                        key={index}
                        style={{
                            ...btnStyle,
                            backgroundColor: 'rgb(142, 0, 49)',
                        }}
                        title="OL"
                    />
                );
            case 'SL':
                return (
                    <button
                        key={index}
                        style={{
                            ...btnStyle,
                            backgroundColor: 'rgb(142, 0, 49)',
                        }}
                        title="SL"
                    />
                );
            default:
                return null;
        }
    });

    return streakElements;
};

export default calculateStreak;
