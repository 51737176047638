import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useIgnoredOnPages = (initial, ignoredOnPages) => {
    const pathname = useLocation().pathname;
    const [ignored, setIgnored] = useState(initial);

    useEffect(() => {
        const isIgonred = () => {
            return ignoredOnPages.find((page) => pathname.includes(page));
        };
        setIgnored(isIgonred());
    }, [pathname, ignoredOnPages]);

    return ignored;
};

export default useIgnoredOnPages;
