import Input from '../../components/Input';
import Select from '../../components/Select';
import translateLabel from '../../helpers/translateLabel';
import roles from '../../constants/roles';
import mandatoryFields from '../../configs/admins';

export default function getFormInput({ name, register, control, error, disabled, index }) {
    if (name === 'roles') {
        return (
            <Select
                key={index}
                label={translateLabel(name)}
                name="roles"
                options={roles}
                control={control}
                error={error}
                disabled={disabled}
                multiple={true}
                isRequired={true}
            />
        );
    } else if (name === 'password') {
        return !disabled ? (
            <Input
                key={index}
                ispassword={true}
                label={translateLabel(name)}
                name={name}
                {...register(name, {
                    required: mandatoryFields.find((field) => (field === name ? true : false)),
                })}
                error={error}
                disabled={disabled}
            />
        ) : null;
    } else {
        return (
            <Input
                key={index}
                label={translateLabel(name)}
                name={name}
                {...register(name, {
                    required: mandatoryFields.find((field) => (field === name ? true : false)),
                })}
                error={error}
                disabled={disabled}
            />
        );
    }
}
