import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Item from '../../components/Item';
// import Button from '../../components/Button';
import { useParams } from 'react-router-dom';
import getTeamFormInput from './getTeamFormInput';
import clearObjProps from '../../helpers/clearObjProps';
import ItemImage from '../../components/ItemImage';
// import { FaPlus } from 'react-icons/fa';
import translations from '../../translations/de';
import TabSwitcher from '../../components/TabSwitcher';
import Delayed from './Delayed';
import Players from './Players';
//import apiLeague from '../../api/leagues';
import leagues from '../../constants/leagues';
import checkIfWomenLeague from '../../helpers/checkifWomenLeague';

const Team = ({ store }) => {
    const { team_id } = useParams();
    const [resetProp, setResetProp] = useState(null);
    const [edit, setEdit] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const { league_id } = useParams();

    useEffect(() => {
        store.getTeam(team_id);
    }, [store, team_id]);

    const teamModel = clearObjProps({ ...store.selectedTeam }, [
        'id',
        'players',
        'external_id',
        'league_id',
    ]);

    for (const key in teamModel) {
        if (
            (key === 'away_color' ||
                key === 'away_text_color' ||
                key === 'home_color' ||
                key === 'home_text_color') &&
            teamModel[key] === null
        ) {
            teamModel[key] = '#FFFFFF';
        }
    }

    const onSubmitHandler = (e) => {
        error && setError(null);
        setLoading(true);
        store
            .editTeam({
                ...e,
            })
            .then(() => {
                setEdit(false);
                setLoading(false);
            })
            .catch((err) => {
                setError(err);
                setLoading(false);
            });
    };
    return store.selectedTeam ? (
        <Delayed>
            <>
                <Item
                    links={[{ path: `/${league_id}/teams`, name: translations.teams }]}
                    name={store.selectedTeam.name}
                    id={`[ team_ID: ${store.selectedTeam.id} ]`}
                    infoField={`[ External_ID: ${store.selectedTeam.external_id} ]`}
                    itemModel={teamModel}
                    edit={edit}
                    onSubmit={onSubmitHandler}
                    onEdit={() => setEdit(true)}
                    onCancel={() => {
                        error && setError(null);
                        setEdit(false);
                    }}
                    error={error}
                    loading={loading}
                    insertDummyFieldAt={3}
                    determineInputType={({
                        value,
                        name,
                        control,
                        register,
                        error,
                        disabled,
                        index,
                    }) =>
                        getTeamFormInput({
                            value,
                            name,
                            control,
                            register,
                            error,
                            disabled,
                            index,
                            leagues: leagues,
                            resetValue: (name, value) => setResetProp({ [name]: value }),
                        })
                    }
                    resetProp={resetProp}
                >
                    <ItemImage imgSrc={store.selectedTeam.image_url} />
                </Item>

                {
                    <TabSwitcher
                        initialTabName={
                            checkIfWomenLeague(league_id)
                                ? translations.female_player
                                : translations.player
                        }
                    >
                        <TabSwitcher.Tabs>
                            <TabSwitcher.TabPill
                                tabName={
                                    checkIfWomenLeague(league_id)
                                        ? translations.female_player
                                        : translations.player
                                }
                            />
                        </TabSwitcher.Tabs>
                        <TabSwitcher.TabPanel
                            activeWhenTabName={
                                checkIfWomenLeague(league_id)
                                    ? translations.female_player
                                    : translations.player
                            }
                        >
                            <div style={{ marginLeft: '2.5%' }}>
                                {/* <Button
                                    label={translations.player}
                                    onClick={() => {
                                        store.selectPlayer(undefined);
                                        history.push(`/teams/${store.selectedTeam.id}/new_player`);
                                    }}
                                >
                                    <FaPlus style={{ marginRight: '5px' }} />
                                </Button> */}
                            </div>
                            <Players store={store} />
                        </TabSwitcher.TabPanel>
                    </TabSwitcher>
                }
            </>
        </Delayed>
    ) : null;
};

export default observer(Team);
