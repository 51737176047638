import './Page.scss';
import Button from '../Button';
import Loader from '../Loader';
import { ErrorBoundary } from 'react-error-boundary';

const Page = ({ loading = false, children }) => {
    function ErrorFallback({ error, resetErrorBoundary }) {
        return (
            <div className="error_boundaries">
                <h2>Something went wrong...</h2>
                <p>{error.message}</p>
                <Button onClick={resetErrorBoundary}>Try again</Button>
            </div>
        );
    }

    return (
        <div className={`Page`}>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
                {children}
                {loading && (
                    <div className="Page-Loader">
                        <Loader theme="dark" size="big" />{' '}
                    </div>
                )}
            </ErrorBoundary>
        </div>
    );
};

export default Page;
