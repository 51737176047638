import React from 'react';
import './Loader.scss';

export default function Loader({
    size = '',
    theme = '',
    isCentered = false,
    table = false,
    ...restProps
}) {
    return isCentered ? (
        table ? (
            <tr
                className={`loader-wrapper Loader ${size === 'big' ? 'big' : ''} ${
                    theme === 'dark' ? 'dark' : ''
                }`}
                {...restProps}
            />
        ) : (
            <div
                className={`loader-wrapper Loader ${size === 'big' ? 'big' : ''} ${
                    theme === 'dark' ? 'dark' : ''
                }`}
                {...restProps}
            />
        )
    ) : (
        <div
            className={['Loader', size === 'big' ? 'big' : '', theme === 'dark' ? 'dark' : ''].join(
                ' ',
            )}
            {...restProps}
        />
    );
}
