import { types, applySnapshot } from 'mobx-state-tree';

const Match = types
    .model('Match', {
        id: types.identifierNumber,
        begin_date: types.maybeNull(types.string, ''),
        end_date: types.maybeNull(types.string, ''),
        home_team: types.frozen({ id: types.number, name: '', image_url: '' }),
        away_team: types.frozen({ id: types.number, name: '', image_url: '' }),
        home_team_id: types.number,
        away_team_id: types.number,
        venue_id: types.maybeNull(types.number),
        venue: types.frozen({
            arena: types.string,
            id: types.identifierNumber,
        }),
        phase: types.frozen({
            id: types.number,
            external_id: types.number,
            name: '',
            acronym: '',
            sort_order: '',
        }),
        phase_id: types.maybeNull(types.number),
        external_id: types.maybeNull(types.string),
        season_id: types.maybeNull(types.number),
        spectators: types.maybeNull(types.number),
        penalty_home_score: types.maybeNull(types.number),
        penalty_away_score: types.maybeNull(types.number),
        home_score: types.maybeNull(types.number),
        away_score: types.maybeNull(types.number),
        one_third_home_score: types.maybe(types.number),
        one_third_away_score: types.maybe(types.number),
        two_thirds_home_score: types.maybe(types.number),
        two_thirds_away_score: types.maybe(types.number),
        three_thirds_home_score: types.maybe(types.number),
        three_thirds_away_score: types.maybe(types.number),
        //  rest
    })
    .actions((self) => ({
        editMatch(edited) {
            applySnapshot(self, { ...self, ...edited });
        },
    }));

export default Match;
