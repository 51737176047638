import { useState, useEffect } from 'react';

const useModal = (initial, modalRef = null, disabled = false) => {
    const [modal, setModal] = useState(initial);

    useEffect(() => {
        if (modal) {
            document.querySelector('html').style.overflow = 'hidden';
            document.querySelector('html').style.paddingRight = '15px';
        } else {
            document.querySelector('html').style.overflow = 'auto';
            document.querySelector('html').style.paddingRight = 0;
        }
    }, [modal]);

    useEffect(() => {
        const handleClickOutsideModal = (event) => {
            // close Modal if it is cicked outside the Modal
            if (
                modalRef &&
                modalRef.current &&
                !modalRef.current.contains(event.target) &&
                !disabled
            ) {
                setModal(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutsideModal);
        return () => document.removeEventListener('mousedown', handleClickOutsideModal);
    }, [modalRef, disabled]);

    return [modal, (value) => setModal(value)];
};

export default useModal;
