import { useState, useEffect } from 'react';
import Item from '../../components/Item';
import { observer } from 'mobx-react-lite';
import { observe } from 'mobx';
import { useHistory } from 'react-router-dom';

import getMatchFormInput from './getMatchFormInput';

import translations from '../../translations/de';

import debounce from 'lodash.debounce';

import MatchAPI from '../../api/matches';

import { testspieleID, preseasonID } from '../../constants/TournamentID';

const NewMatch = ({ store, isTest = false, isPreseason = false, showLeagues = false }) => {
    const history = useHistory();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [venues, setVenues] = useState([]);
    const [venueSearch, setVenueSearch] = useState('');
    const [phases, setPhases] = useState([]);
    const [teams, setTeams] = useState([]);

    const league_id = history?.location?.state?.league_id;
    const tournament_id = history?.location?.state?.tournament_id;
    const preseason_id = history?.location?.state?.preseason_id;

    const handleVenueSearch = debounce((e) => {
        setVenueSearch(e);
    }, 400);

    useEffect(() => {
        const getVenues = async () => {
            const params = { arena: venueSearch };
            const res = await MatchAPI.getVenues({ params });
            setVenues(res.data.items);
        };

        venueSearch !== '' && getVenues();
    }, [venueSearch]);

    useEffect(() => {
        const getPhases = async () => {
            const res = await MatchAPI.getPhases({
                params: { league_id: isTest ? undefined : isPreseason ? undefined : league_id },
            });
            setPhases(res.data.items);
        };
        getPhases();
    }, [league_id, isTest, isPreseason]);

    useEffect(() => {
        store.teams.length > 0 && setTeams(store.teams);
        const disposer = observe(store.teams, () => {
            setTeams([...store.teams]);
        });

        return () => disposer(); // Cleanup observer when component unmounts
    }, [store.teams]);

    return (
        <Item
            links={[
                {
                    path: isTest
                        ? `/${tournament_id}/${league_id}/test-matches`
                        : isPreseason
                        ? `/${preseason_id}/${league_id}/preseason-matches`
                        : `/${league_id}/matches`,
                    name: isTest
                        ? translations.test_matches
                        : isPreseason
                        ? translations.pre_season
                        : translations.matches,
                },
            ]}
            name={`${
                isTest
                    ? translations.test_match
                    : isPreseason
                    ? translations.pre_season
                    : translations.match
            } ${translations.create}`}
            itemModel={{
                begin_date: '',
                end_date: '',
                home_team_id: '',
                away_team_id: '',
                venue_id: null,
                season: '',
                phase_id: null,
                external_id: '',
                spectators: 0,
                home_score: 0,
                away_score: 0,
                one_third_home_score: 0,
                one_third_away_score: 0,
                two_thirds_home_score: 0,
                two_thirds_away_score: 0,
                three_thirds_home_score: 0,
                three_thirds_away_score: 0,
                overtime_home_score: 0,
                overtime_away_score: 0,
                penalty_home_score: 0,
                penalty_away_score: 0,
            }}
            error={error}
            edit={true}
            onSubmit={(event) => {
                if (
                    Number(event.home_score) ===
                    Number(event.one_third_home_score) +
                        Number(event.two_thirds_home_score) +
                        Number(event.three_thirds_home_score)
                ) {
                    if (
                        Number(event.away_score) ===
                        Number(event.one_third_away_score) +
                            Number(event.two_thirds_away_score) +
                            Number(event.three_thirds_away_score)
                    ) {
                        setLoading(true);
                        error && setError(null);
                        store
                            .addMatch({
                                ...event,
                                league_id: Number(league_id),
                                tournament_id: isTest
                                    ? testspieleID
                                    : isPreseason
                                    ? preseasonID
                                    : undefined,
                            })
                            .then(() => {
                                setLoading(false);
                                history.goBack();
                            })
                            .catch((err) => {
                                setError(err);
                                setLoading(false);
                            });
                    } else {
                        setError(translations.guest_score_error);
                        setLoading(false);
                    }
                } else {
                    setError(translations.home_score_error);
                    setLoading(false);
                }
            }}
            onCancel={() => history.goBack()}
            loading={loading}
            determineInputType={({ value, name, register, control, error, isDisabled, index }) =>
                getMatchFormInput({
                    value,
                    name,
                    register,
                    control,
                    error,
                    isDisabled,
                    index,
                    teams,
                    venueOptions: venues,
                    phaseOptions: phases,
                    handleVenueSearch,
                    isTest,
                    showLeagues,
                })
            }
        />
    );
};

export default observer(NewMatch);
