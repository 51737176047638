import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import Select from '../../components/Select';
import ItemImage from '../../components/ItemImage';
import translations from '../../translations/de';
import Table from '../../components/Table';
import { observer } from 'mobx-react-lite';
import StatsFilter from '../../components/StatsFilters';
import debounce from 'lodash.debounce';
import APITeam from '../../api/teams';
import translateLabel from '../../helpers/translateLabel';
import Pagination from '../../components/Pagination';
import './stats.scss';
import handleSort from '../../helpers/sortingTableColumns';
import { useStatsFilterContext } from '../../context/statsFilters';

const PlayerStatsTable = ({ store }) => {
    const [queryParams, setQueryParams] = useState(undefined);
    const { league_id } = useParams();
    const [searchName, setSearchName] = useState(null);
    const [playerOptions, setPlayerOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sortBy, setSortBy] = useState('');
    const { filters, handleFilterChange, resetFilter } = useStatsFilterContext();

    const teamRef = useRef();
    const phaseRef = useRef();

    const cleanupFunction = useCallback(() => {
        const currentRefTeam = teamRef.current;
        const currentRefPhase = phaseRef.current;
        if (currentRefTeam) {
            currentRefTeam.select.setValue();
        }
        if (currentRefPhase) {
            currentRefPhase.select.clearValue();
        }
    }, [teamRef, phaseRef]);

    const handleSearchName = debounce((e) => {
        setSearchName(e);
    }, 500);

    useEffect(() => {
        store.getTeams(league_id);
        store.getPhases(league_id);

        return () => {
            store.clearStats();
            cleanupFunction();
        };
    }, [league_id, store, cleanupFunction]);

    useEffect(() => {
        league_id &&
            queryParams &&
            store?.getPlayerStats({
                league_id: league_id,
                sort: sortBy,
                page: store.selectedPagePlayers,
                ...queryParams,
            });
    }, [store, store.selectedPagePlayers, league_id, queryParams, sortBy]);

    useEffect(() => {
        if (searchName !== '' && searchName !== null) {
            setLoading(true);
            APITeam.getPlayers({ params: { league_id, first_name: searchName } }).then((res) => {
                setPlayerOptions(res.data.items);
                setLoading(false);
            });
        }
    }, [searchName, league_id]);

    const handleQueries = useCallback(
        (e) => {
            setQueryParams((prevParams) => ({ ...prevParams, ...e }));
        },
        [setQueryParams],
    );

    var teamOptions = [{ team_external_id: null, name: translations.all }, ...store?.teams];

    return (
        <>
            <StatsFilter
                showSeasonFilter={true}
                showPositionFilter={true}
                showNationalityFilter={true}
                activeSection={`${league_id}-player_stats`}
                returnQueries={(e) => handleQueries(e)}
                filters={filters}
                resetFilter={resetFilter}
                handleFilterChange={handleFilterChange}
            >
                <>
                    <div className="filter-box">
                        <Select
                            key={`${league_id}-team_select`}
                            ref={teamRef}
                            label="Choose Team"
                            options={teamOptions.map((team) => ({
                                value: team.external_id,
                                label: team.name,
                            }))}
                            defaultValue={{ label: translations.all, value: null }}
                            onChangeCallback={(e) =>
                                setQueryParams((prevParams) => ({
                                    ...prevParams,
                                    team_external_id: e?.value ?? null,
                                }))
                            }
                        />
                    </div>
                    <div className="filter-box">
                        <Select
                            key={`${league_id}-phase_select`}
                            ref={phaseRef}
                            label="Choose Phase"
                            options={store?.phases.map((phase) => ({
                                value: phase.id,
                                label: phase.acronym,
                            }))}
                            isClearable={true}
                            onChangeCallback={(e) =>
                                setQueryParams((prevParams) => ({
                                    ...prevParams,
                                    phase_id: e?.value ?? null,
                                }))
                            }
                        />
                    </div>
                    <div className="filter-box">
                        <Select
                            ref={teamRef}
                            label={translations.player_name}
                            options={playerOptions?.map((player) => ({
                                value: player.id,
                                label: `${player.first_name} ${player.last_name}`,
                            }))}
                            isClearable={true}
                            isLoading={loading}
                            onInputChange={(e) => handleSearchName(e)}
                            onChangeCallback={(e) =>
                                setQueryParams((prevParams) => ({
                                    ...prevParams,
                                    player_id: e?.value ?? null,
                                }))
                            }
                        />
                    </div>
                </>
            </StatsFilter>
            {
                <Table
                    isLoaded={store.dataLoaded}
                    tableName={translations.players}
                    sortTable={(e) => handleSort(e, sortBy, setSortBy)}
                    tablePagination={
                        store.totalPagesPlayers > 1 && (
                            <Pagination
                                totalPages={store.totalPagesPlayers}
                                selectedPage={store.selectedPagePlayers}
                                onSelectedPageChange={(selected) =>
                                    store.setSelectedPagePlayers(selected)
                                }
                            />
                        )
                    }
                    columns={[
                        {
                            Header: 'NO',
                            accessor: 'rank',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.player,
                            title: 'First name and last name',
                            accessor: 'player',
                            isFixedWider: true,
                            Cell: ({ value }) => (
                                <div>{`${value?.first_name} ${value?.last_name}`}</div>
                            ),
                        },
                        {
                            Header: 'T',
                            accessor: 'team_logo',
                            Cell: ({ value }) => (
                                <div>
                                    <ItemImage
                                        imgSrc={value}
                                        alt="team"
                                        listView={true}
                                        smaller={true}
                                    />
                                </div>
                            ),
                        },
                        {
                            Header: 'POS',
                            title: 'Position',
                            accessor: 'position',
                            Cell: ({ value }) => (
                                <div title={translateLabel(value)}>
                                    {translateLabel(value)?.charAt(0)}
                                </div>
                            ),
                        },
                        {
                            Header: 'GP',
                            title: 'Games Played',
                            accessor: 'games_played',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: 'G',
                            title: 'Tore',
                            accessor: 'goals',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: 'A',
                            title: 'Assists',
                            accessor: 'assists',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: 'P',
                            title: 'Punkte',
                            accessor: 'points',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: 'PIM',
                            accessor: 'penalty_in_minutes',
                            title: 'Penalty in minutes',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: '+/-',
                            accessor: 'plus_minus',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: 'P/GP',
                            accessor: 'points_per_game_played',
                            title: 'Points Per Game',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: 'SOG',
                            accessor: 'shots_on_goal',
                            title: 'Shots On Goal',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: 'S%',
                            accessor: 'shots_on_goal_percenage',
                            title: 'Goals / Shots On Goal',
                            Cell: ({ value }) => (
                                <div>{isNaN(value) ? Number(0).toFixed(2) : value}</div>
                            ),
                        },
                        // {
                        //     Header: 'SHM TOTAL',
                        //     accessor: 'missed_shots',
                        //     title: 'Schüsse neben das Tor',
                        //     Cell: ({ value }) => <div>{value}</div>,
                        // },
                        // {
                        //     Header: 'SHP TOTAL',
                        //     accessor: 'shots_on_post',
                        //     title: 'Schüsse an die Torumrandung',
                        //     Cell: ({ value }) => <div>{value}</div>,
                        // },
                        {
                            Header: 'BkS',
                            accessor: 'blocked_shots',
                            title: 'geblockte Schüsse',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: 'FOW',
                            title: 'gewonnene Bullys',
                            accessor: 'faceoff_won',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: 'FOL',
                            title: 'verlorene Bullys',
                            accessor: 'faceoff_lost',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: 'FO%',
                            title: 'gewonnene Bullys in %',
                            accessor: 'faceoff_percentage',
                            Cell: ({ value }) => (
                                <div style={{ whiteSpace: 'no-wrap' }}>{value}%</div>
                            ),
                        },
                        {
                            Header: 'PPP',
                            title: 'Powerplay Points',
                            accessor: 'powerplay_points',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: 'SHG',
                            title: 'Shorthanded Goals',
                            accessor: 'boxplay_points',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: 'OTG',
                            title: 'Overtime Goals',
                            accessor: 'overtime_goals',
                            Cell: ({ value }) => <div>{value}</div>,
                        },

                        {
                            Header: 'TOI',
                            title: 'Eiszeit total',
                            accessor: 'time_on_ice_total',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: 'TOI PP',
                            title: 'Eiszeit bei Überzahl',
                            accessor: 'time_on_ice_pp',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: 'TOI PK',
                            title: 'Eiszeit bei Überzahl',
                            accessor: 'time_on_ice_bp',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                    ]}
                    data={store?.playerStats.map(
                        (
                            {
                                player,
                                team,
                                games_played,
                                goals,
                                assists,
                                points,
                                penalty_in_minutes,
                                plus,
                                minus,
                                shots_on_goal,
                                missed_shots,
                                shots_on_post,
                                blocked_shots,
                                faceoff_won,
                                faceoff_lost,
                                faceoff_percentage,
                                powerplay_points,
                                boxplay_points,
                                overtime_goals,
                                game_winning_goals,
                                time_on_ice_total,
                                time_on_ice_pp,
                                time_on_ice_bp,
                            },
                            index,
                        ) => {
                            return {
                                rank: (store.selectedPagePlayers - 1) * 50 + index + 1,
                                player,
                                team_logo: team.image_url,
                                position: player?.position,
                                games_played,
                                goals,
                                assists,
                                points,
                                penalty_in_minutes,
                                plus_minus: plus - minus,
                                points_per_game_played: (points / games_played).toFixed(2),
                                shots_on_goal,
                                shots_on_goal_percenage:
                                    shots_on_goal !== 0 ? (goals / shots_on_goal).toFixed(2) : 0,
                                missed_shots,
                                shots_on_post,
                                blocked_shots,
                                faceoff_won,
                                faceoff_lost,
                                faceoff_percentage: Number.isInteger(faceoff_percentage)
                                    ? faceoff_percentage
                                    : faceoff_percentage?.toFixed(2),
                                powerplay_points,
                                boxplay_points,
                                overtime_goals,
                                game_winning_goals,
                                time_on_ice_total,
                                time_on_ice_pp,
                                time_on_ice_bp,
                            };
                        },
                    )}
                />
            }
        </>
    );
};

export default observer(PlayerStatsTable);
