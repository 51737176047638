const S3Item = ({ path, onClick }) => {
    const source = `${path}`;
    return (
        <div className="S3-Item" onClick={() => onClick(source)}>
            <img src={source} alt="s3 item" />
        </div>
    );
};

export default S3Item;
