import { types, flow } from 'mobx-state-tree';
import Match from '../matchesStore/Match';
import apiMatches from './../../api/matches';
import requsetErrorMessage from '../../helpers/requsetErrorMessage';

const MatchStatistics = types
    .model('MatchStatistics', {
        matches: types.array(
            types.frozen(
                Match,
                types.frozen({
                    pass_count: types.optional(types.number, 0),
                    lap_seats: types.optional(types.number, 0),
                    accesses_count: types.optional(types.number, 0),
                    opened_accesses: types.optional(types.number, 0),
                    completed: types.optional(types.number, 0),
                    scanned_accesses_count: types.optional(types.number, 0),
                    isEnded: types.optional(types.boolean, false),
                }),
            ),
        ),
        error: types.union(
            types.optional(types.string, ''),
            types.optional(types.array(types.string), []),
        ),
        dataLoaded: types.optional(types.boolean, false),
    })

    .actions((self) => ({
        getMatchStatistics: flow(function* getMatchStatistics() {
            self.error = '';
            try {
                const matches = yield apiMatches.getMatchStatistics({});
                self.dataLoaded = true;
                self.matches = [...matches.data.map((match) => ({ ...match, isEnded: false }))];
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
    }));

export default MatchStatistics;
