import './Header.scss';
import navigationConfig from '../../configs/navigation';
import useIgnoredOnPages from './../../customHooks/useIgnoredOnPages';
import { useHistory } from 'react-router-dom';
import { FaSignOutAlt, FaBars } from 'react-icons/fa';
import Logo from './../../assets/images/logo/evz-logo.svg';
import API from './../../api/auth';
import Paths from '../../constants/paths';
import Dropdown from '../Dropdown';
import translations from '../../translations/de';

export default function Header({ store, adminStore }) {
    const ignored = useIgnoredOnPages(true, navigationConfig.ignoredOnPages);
    const history = useHistory();
    const logoutHandler = async () => {
        try {
            await API.logout();
            adminStore.removeAdmin();
            history.push(Paths.LOGIN);
        } catch (error) {
            console.log('error => ' + error);
        }
    };
    return !ignored ? (
        <div className="Header">
            <div className="Header-Main">
                <button className="toggle-btn" onClick={store.toggleDrawer}>
                    <FaBars />
                </button>
                <img src={Logo} alt="SCB logo" />
                <p>{translations.app_name}</p>
            </div>
            <Dropdown
                label={`${adminStore.email}`}
                options={[
                    {
                        name: translations.logout,
                        icon: () => <FaSignOutAlt />,
                    },
                ]}
                onOptionClick={logoutHandler}
            />
        </div>
    ) : null;
}
