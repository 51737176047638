import React, { useState, useEffect, useContext, createContext, forwardRef } from 'react';
import './Tabs.scss';

const TabSwitcherContext = createContext({});

const TabPill = forwardRef(({ tabName = '', tabCount = '', tabIcon = null }, ref) => {
    const { activeTabName, setActiveTabName, setParentTabName } = useContext(TabSwitcherContext);
    return (
        <div
            ref={ref}
            className={`Tab ${activeTabName === tabName ? 'selected' : ''}`}
            onClick={() => {
                setActiveTabName(tabName);
                setParentTabName && setParentTabName(tabName);
            }}
        >
            {tabIcon ? (
                <div className="tab-icon">
                    <img src={tabIcon} alt="tab-icon" />
                </div>
            ) : null}
            <p>
                {' '}
                {tabName}{' '}
                {!!tabCount && <span style={{ marginLeft: '5px' }}>{`(${tabCount})`}</span>}
            </p>
        </div>
    );
});

const Tabs = ({ children, halfWidth }) => (
    <div className={`Tabs ${halfWidth && 'half'}`}>{children}</div>
);

const TabPanel = ({ activeWhenTabName, children }) => {
    const { activeTabName } = useContext(TabSwitcherContext);

    return activeWhenTabName === activeTabName ? <div className="Tab-Panel">{children}</div> : null;
};

const TabSwitcher = (
    { initialTabName, children, setParentTabName = null, resetOnInitialTab, halfWidth = false },
    ref,
) => {
    const [activeTabName, setActiveTabName] = useState(initialTabName);

    useEffect(() => {
        resetOnInitialTab && setActiveTabName(resetOnInitialTab);
    }, [resetOnInitialTab]);

    return (
        <TabSwitcherContext.Provider value={{ activeTabName, setActiveTabName, setParentTabName }}>
            {children}
        </TabSwitcherContext.Provider>
    );
};

TabSwitcher.TabPill = TabPill;
TabSwitcher.Tabs = Tabs;
TabSwitcher.TabPanel = TabPanel;

export default TabSwitcher;
