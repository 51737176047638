import Input from '../../components/Input';
import DatePicker from '../../components/DatePicker';
import Select from '../../components/Select';
import mandatoryFields from '../../configs/match';
import translations from '../../translations/de';
import translateLabel from '../../helpers/translateLabel';
import InputEmpty from '../../components/InputEmpty';
import { seasons } from '../../constants/statsFilter';

export default function getUserFormInput({
    value,
    name,
    register,
    control,
    error,
    disabled,
    index,
    teams = [],
    venueOptions = [],
    phaseOptions = [],
    handleVenueSearch,
    showLeagues,
}) {
    if (name === 'begin_date' || name === 'end_date') {
        return (
            <DatePicker
                key={index}
                label={translateLabel(name)}
                selectText={translations.select_date}
                name={name}
                disabled={disabled}
                control={control}
                error={error}
                withTime={true}
                isRequired={mandatoryFields.find((field) => (field === name ? true : false))}
            />
        );
    } else if (name === 'home_team_id' || name === 'away_team_id')
    {
        return (
            <Select
                key={teams.length + '_' + index}
                label={translateLabel(name.replace('_id', ''))}
                name={name}
                options={teams.map((team) => {
                    return {
                        value: team?.id,
                        label: showLeagues
                            ? `${team?.name} ${team?.league?.name ? `(${team.league.name})` : ''}`
                            : `${team?.name}`,
                    };
                })}
                control={control}
                error={error}
                disabled={disabled}
                noOptionsMsg="No teams"
                isRequired={mandatoryFields.find((field) => (field === name ? true : false))}
                isClearable
            />
        );
    } else if (name === 'venue_id') {
        return (
            <Select
                name={name}
                key={index}
                label={translateLabel(name.replace('_id', ''))}
                control={control}
                options={venueOptions.map((venue) => {
                    return { value: venue.id, label: venue.arena };
                })}
                onInputChange={handleVenueSearch}
                disabled={disabled}
                error={error}
                isClearable={true}
            />
        );
    } else if (name === 'phase_id') {
        return (
            <Select
                name={name}
                key={index}
                label={translateLabel(name.replace('_id', ''))}
                control={control}
                options={phaseOptions.map((phase) => {
                    return { value: phase.id, label: phase.name };
                })}
                disabled={disabled}
                error={error}
                isClearable={true}
            />
        );
    } else if (name === 'season') {
        return (
            <Select
                name={name}
                key={index}
                label={translateLabel(name)}
                control={control}
                type={'number'}
                defaultValue={value}
                options={seasons}
                disabled={disabled}
                error={error}
                isRequired={mandatoryFields.find((field) => (field === name ? true : false))}
                isClearable={true}
            />
        );
    } else if (name === 'dummy') {
        return <InputEmpty key={index} />;
    } else if (typeof value === 'number') {
        return (
            <Input
                key={index}
                label={translateLabel(name)}
                name={name}
                type={'number'}
                {...register(name, {
                    required: mandatoryFields.find((field) => (field === name ? true : false)),
                    valueAsNumber: typeof value === 'number' ? true : false,
                })}
                error={error}
                disabled={disabled}
            />
        );
    } else {
        return (
            <Input
                key={index}
                label={translateLabel(name)}
                name={name}
                type="text"
                {...register(name, {
                    required: mandatoryFields.find((field) => (field === name ? true : false)),
                })}
                error={error}
                disabled={disabled}
            />
        );
    }
}
