import React, { useEffect, useState } from 'react';
import Table from '../../components/Table';
import translations from '../../translations/de';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import Loader from '../../components/Loader';
import ItemImage from '../../components/ItemImage';
import StatsFilters from '../../components/StatsFilters';
import Select from '../../components/Select';
import { useStatsFilterContext } from '../../context/statsFilters';
//import handleSort from '../../helpers/sortingTableColumns';

const TeamStatsTable = ({ store }) => {
    const { league_id } = useParams();
    //const [sortBy, setSortBy] = useState('');
    const [queryParams, setQueryParams] = useState(undefined);
    const { filters, handleFilterChange } = useStatsFilterContext();

    useEffect(() => {
        league_id && queryParams && store?.getTeamStats(league_id, { ...queryParams });
    }, [league_id, store, queryParams]);

    return (
        <>
            <StatsFilters
                showSeasonFilter={true}
                showHomeGameFilter={true}
                activeSection={`${league_id}-team_stats`}
                returnQueries={(e) => setQueryParams((prevParams) => ({ ...prevParams, ...e }))}
                filters={filters}
                handleFilterChange={handleFilterChange}
            >
                <div className="filter-box">
                    <Select
                        label="Choose Phase"
                        options={store?.phases.map((phase) => ({
                            value: phase.id,
                            label: phase.acronym,
                        }))}
                        isClearable={true}
                        onChangeCallback={(e) =>
                            setQueryParams((prevParams) => ({
                                ...prevParams,
                                phase_id: e?.value ?? null,
                            }))
                        }
                    />
                </div>
            </StatsFilters>
            {store?.dataLoaded ? (
                <>
                    {store.teamStats.length > 0 ? (
                        <Table
                            tableName={translations.teams}
                            //sortTable={(e) => handleSort(e, sortBy, setSortBy)}
                            columns={[
                                {
                                    Header: 'ID',
                                    accessor: 'team_id',
                                    Cell: ({ value }) => <div>{value}</div>,
                                },
                                {
                                    Header: 'Team',
                                    accessor: 'team',
                                    Cell: ({ value }) => (
                                        <div>
                                            <ItemImage
                                                imgSrc={value?.image_url}
                                                alt="team"
                                                listView={true}
                                            />
                                        </div>
                                    ),
                                },
                                // {
                                //     Header: 'Team',
                                //     accessor: 'team_acronym',
                                //     Cell: ({ value }) => <div>{value}</div>,
                                // },
                                {
                                    Header: 'Goals',
                                    accessor: 'goals',
                                    Cell: ({ value }) => <div>{value}</div>,
                                },
                                {
                                    Header: 'P',
                                    title: 'Points',
                                    accessor: 'points',
                                    Cell: ({ value }) => <div>{value}</div>,
                                },
                                {
                                    Header: 'GA',
                                    accessor: 'goals_against',
                                    title: 'Goals against',
                                    Cell: ({ value }) => <div>{value}</div>,
                                },
                                {
                                    Header: 'PIM',
                                    accessor: 'penalty_in_minutes',
                                    title: 'Penalty in minutes',
                                    Cell: ({ value }) => <div>{value}</div>,
                                },
                                {
                                    Header: 'PPG',
                                    accessor: 'power_play_goals',
                                    title: 'Power play goals',
                                    Cell: ({ value }) => <div>{value}</div>,
                                },
                                {
                                    Header: 'PPO',
                                    accessor: 'power_play_opportunities',
                                    title: 'Power play opportunities',
                                    Cell: ({ value }) => <div>{value}</div>,
                                },
                                {
                                    Header: 'PPOO',
                                    accessor: 'power_play_opportunities_opponent',
                                    title: 'Power play opportunities opponent',
                                    Cell: ({ value }) => <div>{value}</div>,
                                },
                                {
                                    Header: 'FOW',
                                    title: 'Faceoffs Won',
                                    accessor: 'faceoffs_won',
                                    Cell: ({ value }) => <div>{value}</div>,
                                },
                                {
                                    Header: 'FOL',
                                    title: 'Faceoffs Lost',
                                    accessor: 'faceoffs_lost',
                                    Cell: ({ value }) => <div>{value}</div>,
                                },
                            ]}
                            data={store.teamStats.map(
                                ({
                                    team_id,
                                    team,
                                    goals,
                                    power_play_goals,
                                    points,
                                    penalty_in_minutes,
                                    power_play_opportunities,
                                    power_play_opportunities_opponent,
                                    goals_against,
                                    faceoffs_lost,
                                    faceoffs_won,
                                }) => {
                                    return {
                                        team_id,
                                        team,
                                        goals,
                                        power_play_goals,
                                        points,
                                        penalty_in_minutes,
                                        power_play_opportunities,
                                        power_play_opportunities_opponent,
                                        goals_against,
                                        faceoffs_won,
                                        faceoffs_lost,
                                    };
                                },
                            )}
                        />
                    ) : (
                        'No Data Yet...'
                    )}
                </>
            ) : (
                <div className="loader-wrapper">
                    <Loader size="big" theme="dark" />
                </div>
            )}
        </>
    );
};

export default observer(TeamStatsTable);
