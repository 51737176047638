import Input from '../../components/Input';
import translateLabel from '../../helpers/translateLabel';
import InputFile from '../../components/InputFile/index.js';
import Select from '../../components/Select';
import { position } from '../../constants/playerConts';
import translations from '../../translations/de';
import DatePicker from '../../components/DatePicker';

export default function getPlayerFormInput({
    value,
    name,
    register,
    error,
    disabled,
    index,
    resetValue,
    control,
    team_acronym,
    teams,
}) {
    if (name === 'image_url') {
        return (
            <InputFile
                key={index}
                label={translateLabel(name)}
                name={name}
                {...register(name, {
                    required: false,
                })}
                error={error}
                disabled={disabled}
                resetValue={(val) => resetValue(name, val)}
                pathToCloudFolder=""
            />
        );
    } else if (name === 'team_id') {
        return (
            <Select
                key={index}
                label={translateLabel(name.replace('_id', ''))}
                name={name}
                options={teams.map((team) => {
                    return {
                        value: team.id,
                        label: (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img
                                    style={{
                                        width: '30px',
                                        height: '30px',
                                        marginRight: '5px',
                                    }}
                                    src={team?.image_url}
                                    alt="team_logo"
                                />
                                {team?.name}
                            </div>
                        ),
                    };
                })}
                control={control}
                error={error}
                disabled={disabled}
                noOptionsMsg="No teams"
                isRequired={true}
                isClearable
            />
        );
    } else if (name === 'position') {
        return (
            <Select
                key={index}
                label={translateLabel(name)}
                name={name}
                options={position}
                control={control}
                error={error}
                disabled={disabled}
                isRequired={true}
            />
        );
    } else if (name === 'birthday') {
        return (
            <DatePicker
                key={index}
                label={translateLabel(name)}
                selectText={translations.select_date}
                name={name}
                defaultValue={new Date(value).setHours(6, 0, 0, 0)}
                control={control}
                disabled={disabled}
                error={error}
                isRequired={false}
            />
        );
    } else if (typeof value === 'number') {
        return (
            <Input
                key={index}
                label={translateLabel(name)}
                name={name}
                type={typeof value}
                {...register(name, {
                    required: false,
                    valueAsNumber: typeof value === 'number' ? true : false,
                })}
                error={error}
                disabled={disabled}
            />
        );
    } else {
        return (
            <Input
                key={index}
                label={translateLabel(name)}
                name={name}
                type="text"
                {...register(name, {
                    required: true,
                })}
                error={error}
                disabled={disabled}
            />
        );
    }
}
