import api from './apiLibrary';

const API = {
    getMatches(params) {
        return api.get('/api/data/game', params);
    },
    getMatch(id, params) {
        return api.get(`/api/data/game/${id}`, params);
    },
    createMatch(data) {
        return api.post('/api/data/game', data);
    },
    editMatch(id, data) {
        return api.put(`/api/data/game/${id}`, data);
    },
    deleteMatch(id) {
        return api.delete(`/api/data/game/${id}`);
    },
    getMatchStatistics(params) {
        return api.get('/api/admin/game/statistics', params);
    },
    getVenues(params) {
        return api.get('/api/data/venue', params);
    },
    getPhases(params) {
        return api.get(`/api/data/phase`, params);
    },
    getLineup(matchID, params) {
        return api.get(`/api/lineup/${matchID}`, params);
    },
};

export default API;
