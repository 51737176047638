const errors = {
    //AdminErrors
    'UserErrors.UserNotFound': 'Wrong Email or Password',
    'UserErrors.UserNotAuthorized':
        'Sorry, you do not have the authority to access the SCB Games Control Panel',
    'UserErrors.UserEmailAlreadyExists': 'Email already exists',
    //ShopItemErrors
    'ShopItemErrors.NotFound': 'Item not found',
    //TeamErrors
    'TeamErrors.NotFound': 'Team not found',
    'TeamErrors.TeamOwnerNotFound': 'Team Owner Not Found',
    //PlayerErrors
    'PlayerErrors.NotFound': 'Player not found',
    'PlayerErrors.TeamMismatch': 'Player and Team mismatch',
    //SeasonErrors
    'SeasonErrors.NotFound': 'Season Not Found',
    //MatchErrors
    'GameErrors.NotFound': 'Match Not Found',
    'GameErrors.FanGamesAlreadyInserted': 'Match already has Fan Games',
    'GameErrors.NeitherIsOwner': "One of the selected teams should be a Company's team",
    //RewardsErrors
    'RewardErrors.NotFound': 'Reward Not Found',
    //FanGameErrors
    'FanGameErrors.NotFound': 'Fan Game Not Found',
    'FanGameErrors.AlreadyInserted': 'Fan Game already inserted',
    'FanGameErrors.AlreadyPublished': 'Fan Game Errors already published',
    'FanGameErrors.AlreadyUnPublished': 'Fan Game already unpublished',
    'FanGameErrors.AlreadyStarted': 'Fan Game already started',
    'FanGameErrors.AlreadyEnded': 'Fan Game already ended',
    'FanGameErrors.NotPublished': 'Fan Game not published',
    'FanGameErrors.NotStarted': 'Fan Game not started',
    'FanGameErrors.NotEnded': 'Fan Game not ended',
    'FanGameErrors.Closed': 'Fan Game closed',
    'FanGameErrors.WrongType': 'Fan Game wrong type',
    'FanGameErrors.DateOrder': 'Fan Game wrong date order',
    'FanGameErrors.AlreadyVoted': 'Fan Game already voted',
    'FanGameErrors.UnknownType': 'Fan Game unknown type',
    'FanGameErrors.RewardsAlreadyAssigned': 'Fan Game rewards already assigned',
    'FanGameErrors.ResultAlreadySet': 'Fan Game result already set',
    'FanGameErrors.PlayerIdMissing': 'Player Id is missing',
    'FanGameErrors.NotEditable': 'Fan Game not editable',
    //QuestionErrors
    'FanGameQuestionErrors.NotFound': 'Fan Game Question not found',
    //FanGameAnswerErrors
    'FanGameAnswerErrors.NotFound': 'Fan Game Answer not found',
    //Feeds error
    'data must be a json string': 'Wrong JSON format',
    'LotteryError.StartAtNotInFuture': 'Start At Date must be in the future!',
    'LotteryError.DrawAtNotInFuture': 'Draw At Date must be in the future!',
    //Server Error
    'Unprocessable Entity': 'Ooops! Something went wrong.',
};

export default errors;
