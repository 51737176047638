import { NavLink } from 'react-router-dom';

export default function LinkItem({ name = '', icon = '', path = '' }) {
    return (
        <NavLink className={`Link-Item`} activeClassName="selected" to={path} title={name}>
            <span className="icon">{icon}</span>
            <span className="name">{name}</span>
        </NavLink>
    );
}
