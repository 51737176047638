import React from 'react';
import Page from './../../components/Page';
import { Route } from 'react-router-dom';
import Paths from '../../constants/paths';
import TableData from './TableData';

const TableTab = () => {
    return (
        <Page>
            <Route exact path={Paths.TABLE}>
                <TableData />
            </Route>
        </Page>
    );
};

export default TableTab;
