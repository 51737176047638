import React, { useEffect, useState } from 'react';

import translations from '../../translations/de';
import API from '../../api/table';

import calculateStreak from '../../helpers/calculateStreak';

import Table from '../../components/Table';
import ItemImage from '../../components/ItemImage';
import { useParams } from 'react-router-dom';
import leagues from '../../constants/leagues';
import Loader from '../../components/Loader';

const TableData = () => {
    const [tableData, setTableData] = useState([]);
    const { league_id } = useParams();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const getData = async () => {
            setLoading(true);
            try {
                var res = await API.getTableData({ params: { league_id: league_id } });
                setTableData(res.data.items);
                setLoading(false);
            } catch (e) {
                console.error(e);
                setLoading(false);
            }
        };
        getData();
    }, [league_id]);

    return loading ? (
        <div className="loader-wrapper">
            <Loader theme="dark" size="big" isCentered={true} />
        </div>
    ) : (
        <Table
            tableName={leagues.find((league) => league.value === Number(league_id)).label}
            columns={[
                {
                    Header: 'ID',
                    isHidden: true,
                    accessor: 'id',
                    Cell: ({ value }) => <div>{value}</div>,
                },
                {
                    Header: 'Team',
                    accessor: 'team',
                    title: translations.team,
                    isFixed: true,
                    Cell: ({ row, value }) => (
                        <div>
                            <p
                                style={{
                                    wordBreak: 'keep-all',
                                    display: 'inline-block',
                                    width: '30px',
                                }}
                            >
                                {row.index + 1}.
                            </p>
                            <ItemImage imgSrc={value.image_url} alt="team" listView={true} />
                            <p style={{ wordBreak: 'keep-all', width: '60px' }}>{value.acronym}</p>
                        </div>
                    ),
                },
                {
                    Header: translations.games_played_short,
                    title: translations.games_played,
                    accessor: 'games_played',
                    Cell: ({ value }) => <div>{value}</div>,
                },
                {
                    Header: translations.goals_short,
                    title: translations.goals,
                    accessor: 'goals',
                    Cell: ({ value }) => <div>{value}</div>,
                },
                {
                    Header: translations.total_points_short,
                    title: translations.total_points,
                    accessor: 'total_points',
                    Cell: ({ value }) => <div>{value}</div>,
                },
                {
                    Header: translations.point_ratio_short,
                    title: translations.point_ratio,
                    accessor: 'point_ratio',
                    Cell: ({ value }) => <div>{value}</div>,
                },
                {
                    Header: translations.reg_time_win_short,
                    title: translations.reg_time_win,
                    accessor: 'regular_time_win',
                    Cell: ({ value }) => <div>{value}</div>,
                },
                {
                    Header: translations.extra_time_win_short,
                    title: translations.extra_time_win,
                    accessor: 'extra_time_win',
                    Cell: ({ value }) => <div>{value}</div>,
                },
                {
                    Header: translations.penalties_win_short,
                    title: translations.penalties_win,
                    accessor: 'penalties_win',
                    Cell: ({ value }) => <div>{value}</div>,
                },
                {
                    Header: translations.penalties_loss_short,
                    title: translations.penalties_loss,
                    accessor: 'penalties_loss',
                    Cell: ({ value }) => <div>{value}</div>,
                },
                {
                    Header: translations.extra_time_loss_short,
                    title: translations.extra_time_loss,
                    accessor: 'extra_time_loss',
                    Cell: ({ value }) => <div>{value}</div>,
                },
                {
                    Header: translations.reg_time_loss_short,
                    title: translations.reg_time_loss,
                    accessor: 'regular_time_loss',
                    Cell: ({ value }) => <div>{value}</div>,
                },
                {
                    Header: 'GDF',
                    title: translations.goals_difference,
                    accessor: 'goals_difference',
                    Cell: ({ value }) => <div>{value}</div>,
                },
                {
                    Header: translations.streak_short,
                    title: translations.streak,
                    accessor: 'streak',
                    isFixedWider: true,
                    Cell: ({ value }) => (
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {calculateStreak(value)}
                        </div>
                    ),
                },
            ]}
            data={tableData.map(
                ({
                    id,
                    team,
                    games,
                    goals_against,
                    goals_for,
                    points,
                    games_won_in_time,
                    games_won_extra_time,
                    games_won_penalty,
                    games_lost_penalty,
                    games_lost_extra_time,
                    games_lost_in_time,
                    goals_difference,
                    streak,
                }) => {
                    return {
                        id,
                        team,
                        games_played: games,
                        goals: `${goals_for}:${goals_against}`,
                        total_points: points,
                        point_ratio: (points / games).toFixed(3),
                        regular_time_win: games_won_in_time,
                        extra_time_win: games_won_extra_time,
                        penalties_win: games_won_penalty,
                        penalties_loss: games_lost_penalty,
                        extra_time_loss: games_lost_extra_time,
                        regular_time_loss: games_lost_in_time,
                        goals_difference,
                        streak,
                    };
                },
            )}
        />
    );
};

export default TableData;
