import api from './apiLibrary';

const API = {
    login(data) {
        return api.post('/api/auth/admin/login', data);
    },
    logout() {
        return api.post('/api/auth/admin/logout');
    },
    getMeUser() {
        return api.get('/api/auth/admin/me');
    },
};

export default API;
