import React, { forwardRef, useState } from 'react';
import './Input.scss';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import translations from '../../translations/de';

const Input = forwardRef(
    ({ label = '', error = false, icon = {}, ispassword = false, style, ...restProps }, ref) => {
        const [textVisibility, setTextVisibility] = useState(ispassword ? false : true);

        return (
            <div className="Input" style={style}>
                {label && (
                    <div className="label">
                        <span>{label} </span>
                    </div>
                )}

                <input
                    ref={ref}
                    className={[error ? 'error' : ''].join(' ')}
                    type={textVisibility ? 'text' : 'password'}
                    autoComplete="off"
                    {...restProps}
                    min={restProps.type === 'number' ? 0 : undefined}
                />
                <span className="error-msg">
                    {error && (
                        <p>{error.type === 'required' ? translations.required : error.message} </p>
                    )}
                </span>
                {icon && Object.keys(icon).length !== 0 && (
                    <div className="icon">
                        <img src={icon.src} alt="icon" onClick={icon.onClick} />
                    </div>
                )}
                {ispassword && (
                    <div className="icon">
                        {textVisibility ? (
                            <FaEyeSlash onClick={() => setTextVisibility(false)} />
                        ) : (
                            <FaEye onClick={() => setTextVisibility(true)} />
                        )}
                    </div>
                )}
            </div>
        );
    },
);

export default Input;
