import MatchesStore from '../models/matchesStore/Matches';
import MatchStatisticsStore from '../models/matchStatisticsStore/MatchStatistics';
import ContactsStore from '../models/contactsStore/Contacts';
import SeasonsStore from '../models/seasonsStore/Seasons';
import AdminsStore from '../models/adminsStore/Admins';
import TeamsStore from '../models/teamsStore/Teams';
import CalendarStore from '../models/calendarStore/Calendar';
import StatsStore from '../models/statsStore/Stats';

const teams = TeamsStore.create();
const admins = AdminsStore.create();
const seasons = SeasonsStore.create();
const matches = MatchesStore.create({ currentSeasonId: null });
const matchStatistics = MatchStatisticsStore.create();
const contacts = ContactsStore.create();
const calendar = CalendarStore.create({ selectedDate: new Date() });
const stats = StatsStore.create();

const stores = {
    teams,
    admins,
    seasons,
    matches,
    matchStatistics,
    contacts,
    calendar,
    stats,
};

export default stores;
