import './Pagination.scss';
import React, { useState, useEffect } from 'react';
import { FaAngleLeft, FaAngleRight, FaAngleDoubleLeft, FaAngleDoubleRight } from 'react-icons/fa';

export default function Pagination({ totalPages, selectedPage, onSelectedPageChange }) {
    const [pages, setPages] = useState([]);

    useEffect(() => {
        const generatePages = (currentPage) => {
            let min = 1;
            let range = 5;

            if (range > totalPages) range = totalPages;

            let start = currentPage - Math.floor(range / 2);
            start = Math.max(start, min);
            start = Math.min(start, min + totalPages - range);

            setPages(Array.from({ length: range }, (el, i) => start + i));
        };
        generatePages(selectedPage);
    }, [totalPages, selectedPage]);

    return totalPages ? (
        <div className="Pagination">
            <button disabled={selectedPage === 1} onClick={() => onSelectedPageChange(1)}>
                <FaAngleDoubleLeft />
            </button>
            <button
                disabled={selectedPage <= 1}
                onClick={() => onSelectedPageChange(selectedPage - 1)}
            >
                <FaAngleLeft />
            </button>
            {pages.map((page, i) => {
                return (
                    <button
                        key={i}
                        id={page}
                        onClick={(e) => onSelectedPageChange(parseInt(e.currentTarget.id, 10))}
                        disabled={selectedPage === page}
                        className={selectedPage === page ? 'selected' : ''}
                    >
                        {page}
                    </button>
                );
            })}
            <button
                onClick={() => onSelectedPageChange(selectedPage + 1)}
                disabled={selectedPage === totalPages}
            >
                <FaAngleRight />
            </button>
            <button
                disabled={selectedPage === totalPages}
                onClick={() => onSelectedPageChange(totalPages)}
            >
                <FaAngleDoubleRight />
            </button>
        </div>
    ) : null;
}
