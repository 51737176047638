import './Breadcrumb.scss';
import { Link } from 'react-router-dom';
import { IoArrowBack } from 'react-icons/io5';

export default function Breadcrumb({ name = '', links = [], showBackButton = false }) {
    const goBack = () => {
        window.history.back();
    };

    return (
        <div className="Breadcrumb">
            {showBackButton && (
                <div className="content-header">
                    <IoArrowBack
                        className="close-btn"
                        style={{ cursor: 'pointer', width: '35px', height: '35px' }}
                        onClick={(e) => goBack()}
                    />
                </div>
            )}
            {links.length !== 0 &&
                links.map((link, i) => (
                    <span key={i} onClick={link.onThisLinkClick}>
                        <Link to={{ pathname: link.path, state: link.state }}>{link.name}</Link>
                        {' / '}
                    </span>
                ))}
            <div className="current">{name}</div>
        </div>
    );
}
