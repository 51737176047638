import { useContext, createContext, useState, useMemo, useCallback } from 'react';

export const StatsFiltersContext = createContext();

export const useStatsFilterContext = () => {
    return useContext(StatsFiltersContext);
};

const initialFilters = {
    season: '',
    nationality: null,
    position: null,
};

export const StatsFilterValues = ({ children }) => {
    const [filters, setFilters] = useState({
        season: null,
        nationality: null,
        position: null,
    });

    const handleFilterChange = useCallback((e) => {
        const { name, value } = e.target;
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value,
        }));
    }, []);

    const resetFilter = () => {
        setFilters({ ...initialFilters });
    };

    const value = useMemo(
        () => ({
            filters,
            handleFilterChange,
            resetFilter,
        }),
        [filters, handleFilterChange],
    );

    return <StatsFiltersContext.Provider value={value}>{children}</StatsFiltersContext.Provider>;
};
