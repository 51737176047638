const handleSort = (e, sortBy, setSortBy) => {

    const columns = sortBy.split(';').filter(Boolean); // Split and remove empty entries
    let found = false; // Flag to track if the column is found
    if (
        e === 'team_logo' ||
        e === 'position' ||
        e === 'plus_minus' ||
        e === 'season' ||
        e === 'shots_on_goal_percenage' ||
        e === 'points_per_game_played'
    ) {
        return;
    } else if (e === 'player') {
        const updatedSortBy = columns
            .map((column) => {
                if (column.startsWith(`player.first_name:`)) {
                    found = true; // Set flag as true if column is found
                    const [, order] = column.split(':');
                    // If it's 'desc', remove it from the array
                    return order === 'asc' ? `player.first_name:desc;` : null;
                }
                return column;
            })
            .filter(Boolean) // Remove null values
            .join(';');

        // If the column wasn't found, add it as 'asc'
        if (!found) {
            if (sortBy !== '') {
                setSortBy(`${sortBy};player.first_name:asc`);
            } else {
                setSortBy(`${sortBy}player.first_name:asc`);
            }
        } else {
            setSortBy(updatedSortBy);
        }
    } else {
        const updatedSortBy = columns
            .map((column) => {
                if (column.startsWith(`${e}:`)) {
                    found = true; // Set flag as true if column is found
                    const [col, order] = column.split(':');
                    // If it's 'desc', remove it from the array
                    return order === 'asc' ? `${col}:desc` : null;
                }
                return column;
            })
            .filter(Boolean) // Remove null values
            .join(';');

        // If the column wasn't found, add it as 'asc'
        if (!found) {
            if (sortBy !== '') {
                setSortBy(`${sortBy};${e}:asc`);
            } else {
                setSortBy(`${sortBy}${e}:asc`);
            }
        } else {
            setSortBy(updatedSortBy);
        }
    }
};

export default handleSort;
