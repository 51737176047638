import { FaHome, FaUsers, FaUserShield, FaHockeyPuck, FaSkating } from 'react-icons/fa';
import { AiOutlineOrderedList } from 'react-icons/ai';
import { GiHockey } from 'react-icons/gi';
import { BiStats } from 'react-icons/bi';
import { MdOutlineChromeReaderMode, MdSportsHockey } from 'react-icons/md';

import Paths from '../../constants/paths';
import translations from '../../translations/de';

const home = [
    {
        name: translations.home,
        icon: <FaHome />,
        path: Paths.HOME,
    },
];

const admin = [
    {
        name: translations.admin_users,
        icon: <FaUserShield />,
        path: Paths.ADMINS,
    },
];

const national_league = [
    {
        name: `${translations.teams} & ${translations.player}`,
        icon: <FaUsers />,
        path: Paths.TEAMS,
    },
    {
        name: translations.matches,
        icon: <FaHockeyPuck />,
        path: Paths.MATCHES,
    },
    {
        name: translations.table,
        icon: <AiOutlineOrderedList />,
        path: Paths.TABLE,
    },
    {
        name: translations.stats,
        icon: <BiStats />,
        path: Paths.STATS,
    },
    {
        name: translations.pre_season,
        icon: <FaSkating />,
        path: Paths.PRESEASON_MATCHES,
    },
    {
        name: translations.test_matches,
        icon: <GiHockey />,
        path: Paths.TEST_MATCHES,
    },
];

const swhl_b = [
    {
        name: `${translations.teams} & ${translations.female_player}`,
        icon: <FaUsers />,
        path: Paths.TEAMS,
    },
    {
        name: `${translations.matches} PFWL`,
        icon: <FaHockeyPuck />,
        path: Paths.MATCHES,
    },
    {
        name: `${translations.table} PFWL`,
        icon: <AiOutlineOrderedList />,
        path: Paths.TABLE,
    },
    {
        name: `${translations.stats} PFWL`,
        icon: <BiStats />,
        path: Paths.STATS,
    },
    {
        name: `${translations.matches} SWN CUP`,
        icon: <MdSportsHockey />,
        path: Paths.MATCHES_CUP,
    },
    {
        name: `${translations.modus} SWN CUP`,
        icon: <MdOutlineChromeReaderMode />,
        path: Paths.MODUS,
    },
    {
        name: `${translations.stats} SWN CUP`,
        icon: <BiStats />,
        path: Paths.STATS_CUP,
    },
    {
        name: translations.pre_season,
        icon: <FaSkating />,
        path: Paths.PRESEASON_MATCHES,
    },
    {
        name: translations.test_matches,
        icon: <GiHockey />,
        path: Paths.TEST_MATCHES,
    },
];

const u20_elit = [
    {
        name: `${translations.teams} & ${translations.player}`,
        icon: <FaUsers />,
        path: Paths.TEAMS,
    },
    {
        name: translations.matches,
        icon: <FaHockeyPuck />,
        path: Paths.MATCHES,
    },
    {
        name: translations.table,
        icon: <AiOutlineOrderedList />,
        path: Paths.TABLE,
    },
    {
        name: translations.stats,
        icon: <BiStats />,
        path: Paths.STATS,
    },
    {
        name: translations.pre_season,
        icon: <FaSkating />,
        path: Paths.PRESEASON_MATCHES,
    },
    {
        name: translations.test_matches,
        icon: <GiHockey />,
        path: Paths.TEST_MATCHES,
    },
];

const u17_elit = [
    {
        name: `${translations.teams} & ${translations.player}`,
        icon: <FaUsers />,
        path: Paths.TEAMS,
    },
    {
        name: translations.matches,
        icon: <FaHockeyPuck />,
        path: Paths.MATCHES,
    },
    {
        name: translations.table,
        icon: <AiOutlineOrderedList />,
        path: Paths.TABLE,
    },
    {
        name: translations.stats,
        icon: <BiStats />,
        path: Paths.STATS,
    },
    {
        name: translations.pre_season,
        icon: <FaSkating />,
        path: Paths.PRESEASON_MATCHES,
    },
    {
        name: translations.test_matches,
        icon: <GiHockey />,
        path: Paths.TEST_MATCHES,
    },
];

export { admin, national_league, swhl_b, u20_elit, u17_elit, home };
