import React, { useEffect } from 'react';
import Page from './../../components/Page';
import { Route, useParams } from 'react-router-dom';
import Matches from './Matches';
import Match from './Match';
import NewMatch from './NewMatch';
import Paths from '../../constants/paths';
import ErrorMessages from '../../components/ErrorMessages.js';
import { observer } from 'mobx-react-lite';

const MatchesTab = ({ store }) => {
    const { league_id } = useParams();

    useEffect(() => {
        var joinBy = ['league'];
        store.getTeams(league_id, true, joinBy);
    }, [store, league_id]);

    return (
        <Page>
            <div style={{ marginLeft: '2.5%' }}>
                <ErrorMessages messages={store.error} />
            </div>
            <Route exact path={Paths.MATCHES}>
                <Matches store={store} />
            </Route>
            <Route exact path={Paths.MATCHES_CUP}>
                <Matches store={store} />
            </Route>
            <Route exact path={Paths.NEW_MATCH}>
                <NewMatch store={store} />
            </Route>
            <Route exact path={Paths.MATCH}>
                <Match store={store} />
            </Route>
            <Route exact path={Paths.MATCH_CUP}>
                <Match store={store} />
            </Route>
        </Page>
    );
};

export default observer(MatchesTab);
