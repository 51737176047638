import React, { useEffect, useState } from 'react';
import Table from '../../components/Table';
import { observer } from 'mobx-react-lite';
import Button from '../../components/Button';
import { useHistory, useParams } from 'react-router-dom';
import { FaSearch } from 'react-icons/fa';
import translations from '../../translations/de';
import ItemImage from '../../components/ItemImage';
import Loader from '../../components/Loader';
import Select from '../../components/Select';

import leagues from '../../constants/leagues'; // common leagues
import { womenLeaguesOptions } from '../../constants/womensLeagues.js'; // special options for female league

const Teams = ({ store, isWomenLeague = false, swhl_id = null }) => {
    const history = useHistory();
    const { league_id } = useParams();
    const [loading, setLoading] = useState(false);
    const [fetchOthers, setFetchOthers] = useState(false);
    const [selectedLeagueId, setSelectedLeagueId] = useState(null);

    useEffect(() => {
        if (selectedLeagueId !== null) {
            setLoading(true);
            store
                .getTeams(isWomenLeague ? swhl_id : selectedLeagueId, fetchOthers)
                .then(() => setLoading(false));
        }
    }, [store, selectedLeagueId, fetchOthers, isWomenLeague, swhl_id]);

    useEffect(() => {
        setSelectedLeagueId(league_id);
        setFetchOthers(false);
    }, [league_id]);

    const handleTeam = (id) => {
        store.selectTeam(id);
        history.push(`/${league_id}/teams/${id}`);
    };

    const handleLeagueChange = (e) => {
        if (e.value === 'other') {
            setFetchOthers(true);
        } else {
            setFetchOthers(false);
        }
    };

    let defaultLeague = isWomenLeague
        ? womenLeaguesOptions.find((league) => league.value === Number(swhl_id))
        : leagues.find((league) => league.value === Number(league_id));

    return (
        <>
            <div className="league-select-wrapper">
                <Select
                    key={defaultLeague.value}
                    label="League"
                    defaultValue={defaultLeague}
                    options={[defaultLeague, { label: 'Other', value: 'other' }]}
                    onChange={(e) => handleLeagueChange(e)}
                />
            </div>
            <div style={{ marginLeft: '2.5%' }}>
                {/* <Button
                    label={translations.team}
                    onClick={() => {
                        store.selectTeam(undefined);
                        history.push(Paths.NEW_TEAM);
                    }}
                >
                    <FaPlus style={{ marginRight: '5px' }} />
                </Button> */}
            </div>
            {loading ? (
                <div className="loader-wrapper">
                    <Loader size="big" theme="dark" />
                </div>
            ) : store.teams.length !== 0 ? (
                <Table
                    tableName={!isWomenLeague && translations.teams}
                    columns={[
                        {
                            Header: 'ID',
                            accessor: 'id',
                            isHidden: true,
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: 'External ID',
                            accessor: 'external_id',
                            isFixed: true,
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.logo,
                            accessor: 'image_url',
                            Cell: ({ value }) => (
                                <div>
                                    <ItemImage imgSrc={value} alt="team" listView={true} />
                                </div>
                            ),
                        },
                        {
                            Header: translations.name,
                            accessor: 'name',
                            Cell: ({ value }) => <div>{value}</div>,
                        },
                        {
                            Header: '',
                            accessor: 'action',
                            Cell: ({ row }) => (
                                <div>
                                    <Button
                                        onClick={() => {
                                            handleTeam(row.cells[0].value);
                                        }}
                                        title={translations.show}
                                    >
                                        <FaSearch />
                                    </Button>
                                </div>
                            ),
                        },
                    ]}
                    data={store.teams.map(({ id, external_id, image_url, name }) => {
                        return {
                            id,
                            external_id,
                            image_url,
                            name,
                        };
                    })}
                />
            ) : (
                <div className="no-data">No Data Yet...</div>
            )}
        </>
    );
};

export default observer(Teams);
