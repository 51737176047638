import React, { useEffect } from 'react';
import Page from './../../components/Page';
import { observer } from 'mobx-react-lite';
import TabSwitcher from '../../components/TabSwitcher';
import Matches from '../Matches/Matches';
import { phases } from '../../constants/phases';

const ModusTab = ({ store }) => {
    useEffect(() => {
        store.getTeams();
    }, [store]);

    return (
        <Page>
            <TabSwitcher initialTabName={phases[0].acronym}>
                <TabSwitcher.Tabs>
                    {phases.length > 0 &&
                        phases.map((phase) => {
                            return (
                                <TabSwitcher.TabPill
                                    tabName={phase.acronym}
                                    key={`tabName-${phase.acronym}`}
                                />
                            );
                        })}
                </TabSwitcher.Tabs>
                {phases.length > 0 &&
                    phases.map((phase) => {
                        return (
                            <TabSwitcher.TabPanel
                                key={`tabValue-${phase.external_id}`}
                                activeWhenTabName={phase.acronym}
                            >
                                <Matches store={store} isCupGames={true} phase_id={phase.id} />
                            </TabSwitcher.TabPanel>
                        );
                    })}
            </TabSwitcher>
        </Page>
    );
};

export default observer(ModusTab);
