import api from './apiLibrary';

const API = {
    getTeams(params) {
        return api.get('/api/data/team', params);
    },
    getTeam(id, params) {
        return api.get(`/api/data/team/${id}`, params);
    },
    createTeam(data) {
        return api.post('/api/admin/team', data);
    },
    editTeam(id, data) {
        return api.put(`/api/set-data/team/${id}`, data);
    },
    /// player
    getPlayers(params) {
        return api.get(`/api/data/player`, params);
    },
    getPlayer(id, params) {
        return api.get(`/api/data/player/${id}`, params);
    },
    getOwnerPlayers() {
        return api.get('/api/admin/player/owner');
    },
    createPlayer(data) {
        return api.post('/api/admin/player', data);
    },
    editPlayer(id, data) {
        return api.put(`/api/set-data/player/${id}`, data);
    },
    getOwnersPlayers() {
        return api.get('/api/admin/player/owner');
    },
    deletePlayer(id) {
        return api.delete(`/api/admin/player/${id}`);
    },
    revivePlayer(id) {
        return api.put(`/api/admin/player/${id}/revive`);
    },
};

export default API;
