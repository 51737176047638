import { types, applySnapshot } from 'mobx-state-tree';

const Team = types
    .model('Team', {
        id: types.identifierNumber,
        image_url: types.maybeNull(types.string),
        acronym: types.maybeNull(types.string),
        external_id: types.maybeNull(types.number),
        name: types.maybeNull(types.string),
        league_id: types.maybeNull(types.number),
    })
    .actions((self) => ({
        editTeam(edited) {
            applySnapshot(self, { ...self, ...edited });
        },
        addPlayer(player) {
            self.players.push(player);
        },
        editPlayer(player) {
            self.players.forEach((element) => {
                if (element.id === player.id) {
                    element.editPlayer(player);
                }
            });
        },
    }));

export default Team;
