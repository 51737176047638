import { types, flow } from 'mobx-state-tree';
import Contact from './Contact';
import API from '../../api/contacts';
import requsetErrorMessage from '../../helpers/requsetErrorMessage';
import clearEmptyFields from '../../helpers/clearEmptyFields';
import clearObjNulls from '../../helpers/clearObjNulls';

const ContactsStore = types
    .model('ContactsStore', {
        contacts: types.optional(types.array(Contact), []),
        selectedContact: types.maybe(types.reference(Contact)),
        totalPages: types.optional(types.number, 0),
        selectedPage: types.optional(types.number, 1),
        searchQuery: types.optional(types.string, ''),
        error: types.union(
            types.optional(types.string, ''),
            types.optional(types.array(types.string), []),
        ),
        dataLoaded: types.optional(types.boolean, false),
    })
    .actions((self) => ({
        getContacts: flow(function* getContacts(searchQuery) {
            self.error = '';
            try {
                const res = yield API.getContacts({
                    params: {
                        search: searchQuery ? searchQuery : undefined,
                        limit: 10,
                        page: self.selectedPage,
                    },
                });
                self.dataLoaded = true;
                self.contacts = res.data.items;
                self.totalPages = res.data.total_pages_count;
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        setSelectedPage(page) {
            self.selectedPage = page;
        },
        setSearchQuery(query) {
            self.searchQuery = query;
        },
        selectContact(id) {
            self.selectedContact = id;
        },
        getContact: flow(function* getContact(id) {
            self.error = '';
            try {
                const res = yield API.getContact(id);
                self.dataLoaded = true;
                self.contacts = [];
                self.contacts.push(res.data);
                self.selectedContact = res.data.id;
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        editContact: flow(function* editContact(edited) {
            var updated = clearEmptyFields(clearObjNulls(edited));
            try {
                const res = yield API.editContact(self.selectedContact.id, {
                    ...updated,
                });
                self.selectedContact.editContact(res.data);
            } catch (error) {
                throw error;
            }
        }),
        addContact: flow(function* addContact(contact) {
            try {
                const res = yield API.createContact(clearEmptyFields(clearObjNulls(contact)));
                self.contacts.push(res.data);
            } catch (error) {
                throw error;
            }
        }),
        deleteContact: flow(function* deleteContact(id) {
            try {
                yield API.deleteContact(id);
                const index = self.contacts.findIndex((s) => s.id === id);
                self.contacts.splice(index, 1);
            } catch (error) {
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        setDataLoaded() {
            self.dataLoaded = true;
        },
    }));

export default ContactsStore;
