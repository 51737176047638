const translations = {
    ///app
    app_name: 'EVZ Backoffice',
    team_home: 'EVZ Home',
    team_name: 'EVZ',
    // leagues
    national_league: 'National League',
    //filters
    phase: 'Phase',
    nationality: 'Nationalität',
    player_name: 'Spielersuche',
    striker: 'Strümer',
    defender: 'Verteidiger',
    goalie: 'Goalies',
    // table
    games_played: 'Gespielte Spiele',
    games_played_short: 'GP',
    goals: 'Goals',
    goals_short: 'G',
    total_points: 'Punktetotal',
    total_points_short: 'PTS',
    point_ratio_short: 'P/GP',
    point_ratio: 'Point Ratio',
    reg_time_win: 'Siege nach regulärer Spielzeit',
    reg_time_loss: 'Niederlagen nach regulärer Spielzeit',
    reg_time_win_short: 'W',
    reg_time_loss_short: 'L',
    extra_time_win: 'Siege nach Verlängerung',
    extra_time_loss: 'Niederlagen nach Verlängerung',
    extra_time_win_short: 'OTW',
    extra_time_loss_short: 'OTL',
    penalties_win: 'Siege nach Penaltyschiessen',
    penalties_loss: 'Niederlagen nach Penaltyschiessen',
    penalties_win_short: 'SOW',
    penalties_loss_short: 'SOL',
    streak: 'Letze Spiele',
    streak_short: 'STREAK',
    goals_difference: 'Goalds difference',
    //
    mobile_version: 'Mobile Version',
    username: 'Benutzername',
    password: 'Passwort',
    required: 'Erforderlich',
    login: 'Login',
    logout: 'Logout',
    edit: 'editieren',
    save: 'Speichern',
    cancel: 'Abbrechen',
    delete: 'löschen',
    show: 'zeigen',
    close: 'Schließen',
    search_title: 'Suchen',
    search: 'Suchen...',
    select: 'Wählen...',
    new: 'Neu',
    create: 'erfassen',
    general: 'Allgemein',
    buyer: 'Käufer',
    owner: 'Eigentümer',
    action: 'Aktion',
    actions: 'Aktionen',
    send: 'Senden',
    send_to: 'Senden an',
    admin_users: 'Admin-Users',
    admin_user: 'Admin-User',
    user: 'Benutzer',
    users: 'Users',
    all_user: 'Alle Nutzer',
    all_sponsors: 'Saisonabo Benutzer',
    type_to_search_users: 'Hinzufügen, um mit Business-Konto zu verknüpfen',
    no_users_found: 'keine Benutzer gefunden',
    no_profiles_found: 'No Results...',
    select_date: 'Datum auswählen...',
    type_to_search_seats: 'eingeben, um nach einem Sitzplatz zu suchen (z. B. sec-1 oder 100).',
    no_seats_found: 'keine Sitze gefunden',
    select_season: 'Spielzeit auswählen...',
    no_seasons_found: 'keine Spielzeit gefunden',
    positive_number: 'Must be positive number!',
    zero_or_positive_number: 'Must be equal to 0 or higher!',
    logo: 'Logo',
    current: 'Aktuell?',
    male: 'Herr',
    female: 'Frau',
    other: 'Andere',
    details: 'Details',
    avatarSizeLabel: 'Recommended 100x100 pixels or lower...',
    imageSizeLabel: 'Recommended 360x800 pixels or higher...',
    //--------------------Tabs------------------
    tab_name: 'Tab Name',
    home: 'Home',
    seat: 'Sitz',
    seats: 'Sitze',
    scanner: 'Scanner',
    news: 'News',
    pass: 'Abo',
    passes: 'Abos',
    passes_column: 'Zutritte',
    passesFriendship: 'Freunde Tickets',
    access: 'eTicket',
    accesses: 'eTickets',
    notification: 'Benachrichtigung',
    notifications: 'Benachrichtigungen',
    teams: 'Teams',
    team: 'Team',
    player: 'Spieler',
    female_player: 'Spielerinnen',
    staff: 'Staff',
    match: 'Spiel',
    test_match: 'Testspiele',
    matches: 'Spiele',
    test_matches: 'Testspiele',
    sector: 'Sektor',
    sectors: 'Sektoren',
    entrances: 'Eingänge',
    seat_groups: 'Blöcke',
    seat_group: 'Block',
    seat_assignment: 'Sitzzuordung',
    seat_assignments: 'Sitzzuordungen',
    season: 'Spielzeit',
    seasonal_pass: 'Saisonkarten',
    single_pass: 'Einzeltickets',
    freunde_pass: 'Fründe Tickets',
    total: 'Total',
    more: 'More',
    seasons: 'Spielzeiten',
    shop_item: 'Shop Item',
    shop_items: 'Shop Items',
    feeds: 'Feeds',
    app_screens: 'App Screens',
    app_screen: 'App Screen',
    banners: 'Banners',
    banner: 'Banner',
    business_profiles: 'Business Profiles',
    business_profile: 'Business Profile',
    instant_rewards: 'Instant Rewards',
    instant_reward: 'Instant Reward',
    search_reward: 'Type to search for reward',
    match_statistics: 'Status',
    match_overview: 'Matches Overview',
    fan_games: 'Fan Games',
    contacts: 'Kontakte',
    contact: 'Kontakt',
    feed: 'Feed',
    deleted_at: 'Deleted?',
    eguma: 'E-Guma',
    graph: 'Grafik',
    data: 'Data Table',
    visited_bracket: 'Percentages',
    passes_used: 'Number of Used Passes',
    percentages: 'Percentages',
    marketingKPI: 'Marketing KPI',
    ticketingKPI: 'Ticketing KPI',
    stats: 'Stats',
    table: 'Tabelle',
    modus: 'Modus',
    cup_games: 'Cup Spiele',
    pre_season: 'Pre-Season',
    //---------------input labels------------------
    // user
    email: 'E-Mail',
    game_id: 'Game ID',
    first_name: 'Vorname',
    last_name: 'Nachname',
    gender: 'Anrede',
    avatar: 'Avatar',
    birthday: 'Geburtsdatum',
    phone: 'Telefon',
    country: 'Land',
    city: 'Ort',
    street: 'Strasse & Nr.',
    zip: 'PLZ',
    has_verified_email: 'Aktiv?',
    is_deleted: 'Ist gelöscht?',
    roles: 'Roles',
    fan_name: 'Fan Name',
    fan_points: 'Fan Points',
    rank: 'Rank',
    fan_coins: 'Fan Coins',
    accept_marketing: 'Marketing?',
    no_rewards_and_purchases: 'has no Rewards and Purchases yet!',
    // notification
    title: 'Titel',
    text: 'Text',
    image: 'Image',
    recipients: 'Empfänger',
    // pass
    assign_token: 'Token',
    type: 'Typ',
    create_anonymous_links: 'Anonymous link?',
    company: 'Firma',
    white_space_error: 'Ticked ID cannot be empty or contain white space!',
    create_all_tickets: 'Create all e-tickets',
    home_games: 'Heimspiele',
    away_games: 'Auswärtsspiele',
    all: 'Alle',
    // access:
    access_token: 'Access Token',
    scanned_at: 'Gescannt bei',
    scan: 'Scan',
    not_scanned: 'Not Scanned',
    no_access: 'No Access',
    wrong_phone_format: 'Falsches Telefon-Format',
    send_sms: 'SMS versenden',
    print_ticket: 'Ticket drucken',
    updated_notification: 'Benachrichtigung wird aktualisiert',
    can_claim_freunde: 'Can Claim Freunde',
    yes: 'Ja',
    no: 'Keine',
    // team
    logo_url: 'Logo Url',
    // player
    players: 'Skater',
    postion: 'Position',
    number: 'Nummer',
    shot_side: 'Shot Side',
    height: 'Height (cm)',
    weight: 'Weight (kg)',
    rooster: 'Roster',
    player_points: 'Spielerpunkte',
    // match
    home_team: 'Heimteam',
    away_team: 'Auswärtsteam',
    opponent: 'Gegner',
    location: 'Ort',
    date: 'Datum',
    begin_date: 'Begin Date',
    end_date: 'End Date',
    time: 'Zeit',
    score: 'Ergebnis',
    penalty_home_score: 'Penalty Score - Home',
    penalty_away_score: 'Penalty Score - Guest',
    home_score_error: "Home score doesn't match with its thirds!",
    guest_score_error: "Guest score doesn't match with its thirds!",
    match_data: 'Match Data',
    lineup: 'Lineup',
    game_stats: 'Game Stats',
    player_stats: 'Player Stats',
    Attacker: 'Stürmer',
    Defence: 'Verteidiger',
    Goalie: 'Goalie',
    // seat
    row: 'Reihe',
    num: 'Sitz Nummer',
    original_seat: 'Ursprünglicher Sitz',
    assigned_seat: 'Zugeordneter Sitz',
    // scanner
    device_id: 'Geräte-ID',
    app_version: 'App-Version',
    covid_token: 'COVID-API Token',
    covid_env: 'COVID-API Umgebung',
    enabled: 'aktiv?',
    //feeds
    created_at: 'Erstellt am',
    updated_at: 'Aktualisiert am',
    screen_title: 'Bildschirmtitel',
    title_label: 'Titelschild',
    url: 'Url',
    // shop item
    shop_item_name: 'Name',
    name: 'Name',
    specialName: 'Vorname / Name',
    description: 'Description',
    image_url: 'Image Url',
    price: 'Price',
    stock: 'Stock',
    sold: 'Sold',
    start_stock: 'Start Stock',
    redeem_description: 'Redeem Description',
    active: 'Active?',
    acquirer: 'Gewinner',
    acquired: 'Acquired',
    active_status: 'Active',
    date_of_sale: 'Datum des Verkaufs',
    date_of_scan: 'Datum des Scans',
    date_of_expire: 'Datum des Ablaufs',
    //shop-item statistics tab
    active_label: 'Shop items have been bought but still not used.',
    available_label: 'Shop items are available to be bought.',
    used_label: 'Shop items have been used.',
    expired_label: 'Shop items have expired.',
    shop_item_active: 'Active',
    shop_item_available: 'Available',
    shop_item_used: 'Used',
    shop_item_expired: 'Exipred',
    // banner
    screen: 'Screen',
    position: 'Position',
    size: 'Size',
    target_url: 'Target Url',
    btn_title: 'Button title',
    banner_id: 'Banner type',
    // banner types
    small_1: 'Small 1',
    small_2: 'Small 2',
    smaill_3: 'Small 3',
    big_no_btn: 'Banner without a Button',
    big_btn: 'Banner with a Button',
    // business profile
    role: 'Position',
    phone_mobile: 'Mobil',
    search_partner: 'Type to search for a business partner',
    noPartnersOptions: 'No partners found',
    user_name_long: 'Bestehendes mySCB-Konto',
    // business partners
    partner: 'Partner',
    business_partners: 'Business Partners',
    business_partner: 'Business Partner',
    address: 'Strasse & Number',
    // business events
    business_events: 'Business Events',
    business_event: 'Business Event',
    start: 'Beginn',
    end: 'Ende',
    program: 'Programm',
    subtitle: 'Anrede',
    status: 'Status',
    event_passed: 'Passed',
    event_upcoming: 'Upcoming',
    passed: 'Passed Events',
    upcoming: 'Upcoming Events',
    event_ongoing: 'Ongoing',
    event_details: 'Event Details',
    invitations: 'Invitations',
    add_remove_invitations: 'Add/Remove Invitations',
    participants: 'Participants',
    attendees: 'Attendees',
    type_to_search_profiles: 'Type to search profiles',
    update_invitations: 'Update Invitations',
    people: 'People',
    select_people: 'Select People',
    selected_people: 'Selected People',
    search_name_email: 'Suchen Name, Email...',
    search_partner_name: 'Suchen Parnter Name...',
    search_by_game: 'Suche nach Spiel',
    postbox: 'Postfach',
    call: 'Einladen',
    // instant rewards
    popup_text: 'PopUp Text',
    popup_title: 'PopUp Title',
    expire_at: 'Expire At',
    rewarded: 'Rewarded',
    winners: 'Winners',
    send_random: 'Send to random ticket holders',
    // matches
    one_third_home_score: 'First Third Score - Home',
    two_thirds_home_score: 'Second Third Score - Home',
    three_thirds_home_score: 'Last Third Score - Home',
    one_third_away_score: 'First Third Score - Guest',
    two_thirds_away_score: 'Second Third Score - Guest',
    three_thirds_away_score: 'Last Third Score - Guest',
    // match statistics
    pass_count: 'Tickets verfügbar',
    lap_seats: 'Schosskinder',
    created: 'Erstellt',
    opened_accesses: 'Geöffnet',
    completed: 'Bereit',
    scanned: 'Gescannt',
    unknown: 'Unbekannt ',
    //fan games
    fan_game_type: 'Fan Game Type',
    ranking: 'Ranking',
    //vouchers
    vouchers: 'Vouchers',
    voucher: 'Voucher',
    upload: 'Hochladen',
    new_voucher_manually: 'Manuell erstellen', // create manually
    new_voucher_from_file: 'Aus Datei erstellen', // create from file
    choose_template: 'Chose Template',
    searchShopItem: 'Type to search for shop item',
    searchRewards: 'Type to search for reward',
    noOptionShopItem: 'Shop Item not found',
    noOptionRewards: 'Reward not found',
    redeem_data: 'Redeem Data',
    price_in_cents: 'Price in Cents',
    order_id: 'Order ID',
    item_id: 'Item ID',
    voucher_code: 'Voucher Code',
    voucher_type: 'Voucher Type',
    consumable: 'Voucher',
    used_at: 'Used At',
    assigned: 'Is assigned',
    used: 'Used',
    expiry_date: 'Expiry Date',
    reward: 'Reward',
    rewards: 'Rewards',
    new_reward: 'New Reward',
    //news
    new_news: 'Create news',
    hidden: 'Hidden',
    publishing_date: 'Publishing date',
    //shop item
    sales: 'Käufe',
    statistics: 'Statistik',
    //lottery game
    lottery_games: 'Lottery Games',
    lottery_game: 'Lottery Game',
    lottery_game_new: 'New Lottery Game',
    lottery_ticket: 'Lottery Ticket',
    drawn: 'Draw?',
    drawn_at: 'Draw At',
    lottery_game_name: 'Lottery Game Name',
    lottery_details: 'Lottery Details',
    is_winner: 'Winner?',
    tickets: 'Tickets',
    draw_lottery: 'Draw Lottery',
};

export default translations;
