import { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Item from '../../components/Item';
import { useHistory, useParams } from 'react-router-dom';
import getPlayerFormInput from './getPlayerFormInput';
import translations from '../../translations/de';
import Paths from '../../constants/paths';

const NewPlayer = ({ store }) => {
    const history = useHistory();
    const { team_id } = useParams();
    const [avatar, setAvatar] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        !store.selectedTeam && store.getTeam(team_id);
    }, [store, team_id]);

    return store.selectedTeam ? (
        <Item
            links={[
                {
                    path: Paths.TEAMS,
                    name: translations.teams,
                },
                {
                    path: `/teams/${store.selectedTeam.id}`,
                    name: store.selectedTeam.title,
                },
            ]}
            name={`${translations.player} ${translations.create}`}
            itemModel={{
                first_name: '',
                last_name: '',
                avatar: avatar,
                external_player_id: null,
                position: '',
                number: '',
                shot_side: '',
                player_points: 0,
                date_of_birth: new Date(new Date().setHours(6, 0, 0, 0)),
                height: '0',
                weight: '0',
                nation: '',
                rooster: true,
            }}
            edit={true}
            error={error}
            loading={loading}
            onSubmit={(event) => {
                setLoading(true);
                error && setError(null);
                store
                    .addPlayer(event)
                    .then(() => {
                        setLoading(false);
                        history.push(`/teams/${store.selectedTeam.id}`);
                    })
                    .catch((err) => {
                        setError(err);
                        setLoading(false);
                    });
            }}
            onCancel={() => history.goBack()}
            determineInputType={({ value, name, register, error, disabled, index, control }) =>
                getPlayerFormInput({
                    value,
                    name,
                    register,
                    error,
                    disabled,
                    index,
                    resetValue: (name, value) => setAvatar({ [name]: value }),
                    control,
                    team_acronym: store.selectedTeam.totomat_acronym,
                })
            }
            resetProp={avatar}
        ></Item>
    ) : null;
};

export default observer(NewPlayer);
