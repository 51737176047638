import Page from '../../components/Page';
import { observer } from 'mobx-react-lite';
import { Route } from 'react-router-dom';
import Admins from './Admins';
import Admin from './Admin';
import NewAdmin from './NewAdmin';
import Paths from '../../constants/paths';
import ErrorMessages from '../../components/ErrorMessages.js/index.js';

const AdminsTab = ({ store }) => {
    return (
        <Page loading={!store.dataLoaded}>
            <div style={{ marginLeft: '2.5%' }}>
                <ErrorMessages messages={store.error} />
            </div>
            <Route exact path={Paths.ADMINS}>
                <Admins store={store} />
            </Route>
            <Route exact path={Paths.ADMIN}>
                <Admin store={store} />
            </Route>
            <Route exact path={Paths.NEW_ADMIN}>
                <NewAdmin store={store} />
            </Route>
        </Page>
    );
};

export default observer(AdminsTab);
