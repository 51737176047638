const clearObjProps = (obj = {}, clearKeys = []) => {
    Object.keys(obj).forEach((key) => {
        if (clearKeys.includes(key)) {
            delete obj[key];
        }
    });
    return obj;
};

export default clearObjProps;
