const formatTime = (value, noUnit) => {
    let time = value
        ? new Date(value).toLocaleTimeString('de-DE', {
              timeStyle: 'short',
              hour12: false,
          })
        : '';
    return time + (noUnit ? '' : 'h');
};

const formatDate = (value, noDay) => {
    let date = value
        ? new Date(value).toLocaleDateString('de-DE', {
              weekday: noDay ? undefined : 'short',
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
          })
        : 'x';
    return date;
};

const chartFormat = (value) => {
    let date = value
        ? new Date(value).toLocaleDateString('de-DE', {
              // weekday: 'short',
              month: 'numeric',
              day: 'numeric',
          })
        : 'x';
    return date;
};

export { formatTime, formatDate, chartFormat };
