const mandatoryFields = [
    'begin_date',
    'end_date',
    'location',
    'home_team_id',
    'away_team_id',
    'season',
];

export default mandatoryFields;
