import './Drawer.scss';
import {
    admin,
    national_league,
    swhl_b,
    u20_elit,
    u17_elit,
    home /*baseData,home, hardware,*/,
} from './links';
import Link from './Link';
import navigationConfig from '../../configs/navigation';
import useIgnoredOnPages from './../../customHooks/useIgnoredOnPages';
import { observer } from 'mobx-react-lite';
import { adminAccessPaths, adminAccessSections } from '../../helpers/adminAccess';
import drawerSections from '../../constants/drawerSections';

import { testspieleID, preseasonID } from '../../constants/TournamentID';

const Drawer = ({ store, adminStore }) => {
    const ignored = useIgnoredOnPages(true, navigationConfig.ignoredOnPages);
    return !ignored ? (
        <div className="Drawer">
            <div className={`Drawer-Content ${store.isDrawerVisible ? '' : 'close'}`}>
                {/* Zero Section - HOME */}
                {home.map((link, index) =>
                    adminAccessPaths(adminStore.roles).find((path) => path === link.path) ? (
                        <Link key={index} name={link.name} icon={link.icon} path={link.path} />
                    ) : null,
                )}
                {/* First section - ADMIN */}
                {adminAccessSections(adminStore.roles).find(
                    (sec) => sec === drawerSections.MANAGEMENT,
                ) ? (
                    <div className={`label ${store.isDrawerVisible ? '' : 'hide'}`}>
                        <span>{drawerSections.MANAGEMENT}</span>
                    </div>
                ) : null}
                {admin.map((link, index) =>
                    adminAccessPaths(adminStore.roles).find((path) => path === link.path) ? (
                        <Link key={index} name={link.name} icon={link.icon} path={link.path} />
                    ) : null,
                )}
                {/*Second Section - NATIONAL LEAGUE*/}
                {adminAccessSections(adminStore.roles).find(
                    (sec) => sec === drawerSections.NATIONAL_LEAGUE,
                ) ? (
                    <div className={`label ${store.isDrawerVisible ? '' : 'hide'}`}>
                        <span>{drawerSections.NATIONAL_LEAGUE}</span>
                    </div>
                ) : null}
                {national_league.map((link, index) =>
                    adminAccessPaths(adminStore.roles).find((path) => path === link.path) ? (
                        <Link
                            key={index}
                            name={link.name}
                            icon={link.icon}
                            path={link.path
                                .replace(':league_id', '1')
                                .replace(':tournament_id', testspieleID)
                                .replace(`:preseason_id`, preseasonID)}
                        />
                    ) : null,
                )}
                {/*Third Section - SWISS_WOMANS_HOCKEY*/}
                {adminAccessSections(adminStore.roles).find(
                    (sec) => sec === drawerSections.WOMENS_HOCKEY,
                ) ? (
                    <div className={`label ${store.isDrawerVisible ? '' : 'hide'}`}>
                        <span>{drawerSections.WOMENS_HOCKEY}</span>
                    </div>
                ) : null}
                {swhl_b.map((link, index) =>
                    adminAccessPaths(adminStore.roles).find((path) => path === link.path) ? (
                        <Link
                            key={index}
                            name={link.name}
                            icon={link.icon}
                            path={
                                link.path.includes('_cup')
                                    ? link.path
                                          .replace(':league_id', '348257')
                                          .replace(':tournament_id', testspieleID)
                                          .replace(`:preseason_id`, preseasonID)
                                    : link.path
                                          .replace(':league_id', '325')
                                          .replace(':tournament_id', testspieleID)
                                          .replace(`:preseason_id`, preseasonID)
                            }
                        />
                    ) : null,
                )}
                {/* Fourth Section - u20_elit */}
                {adminAccessSections(adminStore.roles).find(
                    (sec) => sec === drawerSections.U20_ELIT,
                ) ? (
                    <div className={`label ${store.isDrawerVisible ? '' : 'hide'}`}>
                        <span>{drawerSections.U20_ELIT}</span>
                    </div>
                ) : null}
                {u20_elit.map((link, index) =>
                    adminAccessPaths(adminStore.roles).find((path) => path === link.path) ? (
                        <Link
                            key={index}
                            name={link.name}
                            icon={link.icon}
                            path={link.path
                                .replace(':league_id', '130')
                                .replace(':tournament_id', testspieleID)
                                .replace(`:preseason_id`, preseasonID)}
                        />
                    ) : null,
                )}
                {/* Fifth Section - u17_elit */}
                {adminAccessSections(adminStore.roles).find(
                    (sec) => sec === drawerSections.U17_ELIT,
                ) ? (
                    <div className={`label ${store.isDrawerVisible ? '' : 'hide'}`}>
                        <span>{drawerSections.U17_ELIT}</span>
                    </div>
                ) : null}
                {u17_elit.map((link, index) =>
                    adminAccessPaths(adminStore.roles).find((path) => path === link.path) ? (
                        <span onClick={link.onClick} key={index}>
                            <Link
                                key={index}
                                name={link.name}
                                icon={link.icon}
                                path={link.path
                                    .replace(':league_id', '427')
                                    .replace(':tournament_id', testspieleID)
                                    .replace(`:preseason_id`, preseasonID)}
                            />
                        </span>
                    ) : null,
                )}
            </div>
            <div className={`Drawer-Spacer ${store.isDrawerVisible ? '' : 'hide'}`}></div>
        </div>
    ) : null;
};

export default observer(Drawer);
