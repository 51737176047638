import api from './apiLibrary';

const API = {
    getUsers(params) {
        return api.get('/api/admin/user', params);
    },
    downloadSponsorList(type) {
        return api.get(`api/admin/fan-game/type/${type}/participants/export-sponsor`);
    },
    downloadList(type) {
        return api.get(`api/admin/fan-game/type/${type}/participants/export`);
    },
    getAllEndGameUsers(fan_game_type, params) {
        return api.get(`api/admin/fan-game/type/${fan_game_type}/participants`, params);
    },
    getSingleEndGameRanging(fan_game_id, params) {
        return api.get(`/api/admin/fan-game/${fan_game_id}/participants`, params);
    },
    getEndGameRanking() {
        return api.get(`/api/fan-game-v2/ranking-game-result`);
    },
    getContacts(params) {
        return api.get(`/api/admin/contact`, params);
    },
    getUser(id, params) {
        return api.get(`/api/admin/user/${id}`, params);
    },
    editUser(id, data) {
        return api.put(`/api/admin/user/${id}`, data);
    },
    getItems(id) {
        return api.get(`/api/admin/user/${id}/acquired-items`);
    },
    getFiles() {
        return api.get(`/api/s3/list?path=evz`);
    },
    uploadFile(file) {
        return api.post(`/api/s3/upload?path=evz`, file);
    },
    deleteUser(id) {
        return api.delete(`/api/admin/user/${id}`);
    },
    reviveUser(id) {
        return api.put(`/api/admin/user/${id}/revive`);
    },
};

export default API;
