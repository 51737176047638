import { types } from 'mobx-state-tree';

const TeamStats = types.model('TeamStats', {
    team_id: types.maybeNull(types.number),
    season: types.maybeNull(types.number),
    game_id: types.maybeNull(types.string),
    goals: types.maybeNull(types.string),
    points: types.maybeNull(types.string),
    goals_against: types.maybeNull(types.string),
    power_play_opportunities: types.maybeNull(types.string),
    power_play_goals: types.maybeNull(types.string),
    power_play_opportunities_opponent: types.maybeNull(types.string),
    power_play_goals_opponent: types.maybeNull(types.string),
    faceoffs_won: types.maybeNull(types.string),
    faceoffs_lost: types.maybeNull(types.string),
    penalty_in_minutes: types.maybeNull(types.string),
    faceoff_total: types.maybeNull(types.string),
    team: types.frozen({ image_url: types.string, acronym: types.string, name: types.string }),
});

export default TeamStats;
