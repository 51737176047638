import { types } from 'mobx-state-tree';

const Calendar = types
    .model('Calendar', {
        selectedDate: types.Date,
    })
    .actions((self) => ({
        selectDate(date) {
            self.selectedDate = date;
        },
    }));

export default Calendar;
