import React from 'react';
import TabSwitcher from '../../components/TabSwitcher';
import PlayerStatsTable from './PlayerStatsTable';
import TeamStatsTable from './TeamStatsTable';
import GoaliesStatsTable from './GoaliesStatsTable';
import { observer } from 'mobx-react-lite';
import { StatsFilterValues } from '../../context/statsFilters';

const StatsTable = ({ store }) => {
    return (
        <>
            <StatsFilterValues>
                <TabSwitcher initialTabName="Skater">
                    <TabSwitcher.Tabs>
                        <TabSwitcher.TabPill tabName="Skater" />
                        <TabSwitcher.TabPill tabName="Goalies" />
                        <TabSwitcher.TabPill tabName="Teams" />
                    </TabSwitcher.Tabs>
                    <TabSwitcher.TabPanel activeWhenTabName="Skater">
                        <PlayerStatsTable store={store} />
                    </TabSwitcher.TabPanel>
                    <TabSwitcher.TabPanel activeWhenTabName={'Goalies'}>
                        <GoaliesStatsTable store={store} />
                    </TabSwitcher.TabPanel>
                    <TabSwitcher.TabPanel activeWhenTabName="Teams">
                        <TeamStatsTable store={store} />
                    </TabSwitcher.TabPanel>
                </TabSwitcher>
            </StatsFilterValues>
        </>
    );
};

export default observer(StatsTable);
