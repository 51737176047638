import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { observe } from 'mobx';

import Item from '../../components/Item';
import TabSwitcher from '../../components/TabSwitcher';

import Lineup from './Lineup';

import getMatchFormInput from './getMatchFormInput';
import translations from '../../translations/de';

import clearObjProps from '../../helpers/clearObjProps';

import Placeholder from './../../assets/images/unknown_team.png';

import Breadcrumb from '../../components/Breadcrumb';
import MatchAPI from '../../api/matches';
import debounce from 'lodash.debounce';
import './Match.scss';
import { preseasonID, testspieleID } from '../../constants/TournamentID';

const Match = ({ store, isTest = false, isPreseason = false }) => {
    const { league_id, match_id, tournament_id, preseason_id } = useParams();
    const [edit, setEdit] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [teams, setTeams] = useState([]);
    const matchModel = clearObjProps({ ...store.selectedMatch }, [
        'id',
        'fan_games',
        'season_id',
        'league',
        'phase',
        'venue',
        'home_team',
        'away_team',
    ]);
    const [phases, setPhases] = useState([]);
    const [venues, setVenues] = useState([]);
    const [venueSearch, setVenueSearch] = useState('');

    const handleVenueSearch = debounce((e) => {
        setVenueSearch(e);
    }, 400);

    const onSubmitHandler = (event) => {
        setLoading(true);
        error && setError(null);
        /// before editing check if values are correct

        if (
            Number(event.home_score) ===
            Number(event.one_third_home_score) +
                Number(event.two_thirds_home_score) +
                Number(event.three_thirds_home_score)
        ) {
            if (
                Number(event.away_score) ===
                Number(event.one_third_away_score) +
                    Number(event.two_thirds_away_score) +
                    Number(event.three_thirds_away_score)
            ) {
                store
                    .editMatch({
                        ...event,
                        tournament_id: isTest
                            ? testspieleID
                            : isPreseason
                            ? preseasonID
                            : undefined,
                    })
                    .then(() => {
                        setEdit(false);
                        setLoading(false);
                    })
                    .catch((err) => {
                        setError(err);
                        setLoading(false);
                    });
            } else {
                setError(translations.guest_score_error);
                setLoading(false);
            }
        } else {
            setError(translations.home_score_error);
            setLoading(false);
        }
    };

    useEffect(() => {
        store && store.getMatch(match_id);
    }, [store, match_id]);

    //prefill teams with objects from

    useEffect(() => {
        setPhases([
            { id: store?.selectedMatch?.phase?.id, name: store?.selectedMatch?.phase?.name },
        ]);
        setVenues([
            { id: store?.selectedMatch?.venue?.id, arena: store?.selectedMatch?.venue?.arena },
        ]);
    }, [store.selectedMatch]);

    useEffect(() => {
        const getVenues = async () => {
            const params = { arena: venueSearch };
            const res = await MatchAPI.getVenues({ params });
            setVenues(res.data.items);
        };
        edit && venueSearch !== '' && getVenues();
    }, [venueSearch, venues.length, edit]);

    useEffect(() => {
        const getPhases = async () => {
            const res = await MatchAPI.getPhases({ params: { league_id: league_id } });
            setPhases(res.data.items);
        };
        edit && phases.length < 2 && getPhases();
    }, [league_id, phases.length, edit]);

    useEffect(() => {
        let homeTeam = store?.selectedMatch?.home_team;
        let awayTeam = store?.selectedMatch?.away_team;

        /// filter out teams from stored teams

        if (store.teams.length > 0) {
            let allTeams = [...store.teams, homeTeam, awayTeam];
            let filteredTeams = allTeams.reduce((acc, currentTeam) => {
                if (!acc.find((team) => team.id === currentTeam.id)) {
                    acc.push(currentTeam);
                }
                return acc;
            }, []);
            setTeams(filteredTeams);
        }

        const disposer = observe(store.teams, () => {
            setTeams([...store.teams, homeTeam, awayTeam]);
        });

        return () => {
            disposer();
            setTeams([]);
        }; // Cleanup observer when component unmounts
    }, [store.teams, store.selectedMatch]);

    return (
        <>
            {' '}
            <div
                className="Item-Header"
                style={{ borderBottom: '0px solid black', paddingLeft: '0px' }}
            >
                <Breadcrumb
                    name={
                        store?.selectedMatch?.home_team?.name +
                        ' - ' +
                        store?.selectedMatch?.away_team?.name
                    }
                    links={[
                        {
                            path: isTest
                                ? `/${tournament_id}/${league_id}/test-matches`
                                : isPreseason
                                ? `/${preseason_id}/${league_id}/preseason-matches`
                                : `/${league_id}/matches`,
                            name: translations.matches,
                        },
                    ]}
                />
            </div>
            <TabSwitcher initialTabName={translations.match_data}>
                <TabSwitcher.Tabs>
                    <TabSwitcher.TabPill tabName={translations.match_data} />
                    <TabSwitcher.TabPill tabName={translations.lineup} />
                    <TabSwitcher.TabPill tabName={translations.details} />
                    <TabSwitcher.TabPill tabName={translations.game_stats} />
                    <TabSwitcher.TabPill tabName={translations.player_stats} />
                </TabSwitcher.Tabs>
                <TabSwitcher.TabPanel activeWhenTabName={translations.match_data}>
                    {store.selectedMatch ? (
                        <Item
                            hasNavigation={false}
                            infoField={`[ External_ID: ${store.selectedMatch.external_id} ]`}
                            id={`[ match_ID: ${store.selectedMatch.id} ]`}
                            itemModel={{
                                ...matchModel,
                                phase_id: matchModel?.phase_id,
                                venue_id: matchModel?.venue_id,
                            }}
                            edit={edit}
                            onSubmit={onSubmitHandler}
                            onEdit={() => setEdit(true)}
                            onCancel={() => {
                                error && setError(null);
                                setEdit(false);
                            }}
                            error={error}
                            loading={loading}
                            determineInputType={({
                                value,
                                name,
                                register,
                                control,
                                error,
                                disabled,
                                index,
                            }) =>
                                getMatchFormInput({
                                    value,
                                    name,
                                    register,
                                    control,
                                    error,
                                    disabled,
                                    index,
                                    venueOptions: venues,
                                    phaseOptions: phases,
                                    teams,
                                    handleVenueSearch,
                                    isTest,
                                })
                            }
                        >
                            {' '}
                            <div className="Item-Content-Image">
                                <img
                                    src={store.selectedMatch.home_team.image_url ?? Placeholder}
                                    alt="team one"
                                />
                                VS
                                <img
                                    src={store.selectedMatch.away_team.image_url ?? Placeholder}
                                    alt="team two"
                                />
                            </div>
                        </Item>
                    ) : null}
                </TabSwitcher.TabPanel>
                <TabSwitcher.TabPanel activeWhenTabName={translations.lineup}>
                    <Lineup store={store} />
                </TabSwitcher.TabPanel>
                <TabSwitcher.TabPanel activeWhenTabName={translations.details}>
                    To be added...
                </TabSwitcher.TabPanel>
                <TabSwitcher.TabPanel activeWhenTabName={translations.game_stats}>
                    To be added...
                </TabSwitcher.TabPanel>
                <TabSwitcher.TabPanel activeWhenTabName={translations.player_stats}>
                    To be added...
                </TabSwitcher.TabPanel>
            </TabSwitcher>
        </>
    );
};

export default observer(Match);
