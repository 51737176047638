import React, { useEffect } from 'react';
import Table from '../../components/Table';
//import Input from '../../components/Input';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import Pagination from '../../components/Pagination';
//import Select from '../../components/Select';
//import debounce from 'lodash.debounce';
import translations from '../../translations/de';
import Button from '../../components/Button';
import { FaSearch, FaPlus } from 'react-icons/fa';
import Paths from '../../constants/paths';
//import roles from '../../constants/roles';
//import Modal from '../../components/Modal';
//import useModal from '../../customHooks/useModal';

const Admins = ({ store }) => {
    const history = useHistory();
    //const [accountType, setAccountType] = useState();
    //const [searchQuery, setSearchQuery] = useState('');
    //const [adminID, setAdminID] = useState(null);
    //const modalRef = useRef(null);
    //const [modal, setModal] = useModal(false, modalRef);

    useEffect(() => {
        store.getAdmins(/*searchQuery, accountType*/);
    }, [store, store.selectedPage /*, accountType, searchQuery*/]);

    // const isNotDeletedAlready = (row) => {
    //     return !row.original.deleted_at;
    // };

    const handleAdmin = (id) => {
        history.push(`/admins/${id}`);
    };

    // const onSearchChangeHandler = debounce((e) => {
    //     store.setSelectedPage(1);
    //     setSearchQuery(e.target.value);
    // }, 500);

    return (
        <>
            <div className="tab-controls">
                {/* <Select
                    label={`${translations.admin_user} role`}
                    defaultValue={{ label: 'All', value: null }}
                    options={roles}
                    onChange={(e) => {
                        store.setSelectedPage(1);
                        setAccountType(e.value);
                    }}
                /> */}
                {/* <Input
                    placeholder={translations.search}
                    onChange={onSearchChangeHandler}
                    label={`${translations.admin_user} ${translations.first_name} / ${translations.last_name} /${translations.email}`}
                /> */}
            </div>
            <Button
                label={translations.admin_user}
                onClick={() => {
                    store.selectAdmin(undefined);
                    history.push(Paths.NEW_ADMIN);
                }}
                style={{ marginLeft: '2.5%' }}
            >
                <FaPlus style={{ marginRight: '5px' }} />
            </Button>

            <Table
                tablePagination={
                    store.totalPages > 1 && (
                        <Pagination
                            totalPages={store.totalPages}
                            selectedPage={store.selectedPage}
                            onSelectedPageChange={(selected) => store.setSelectedPage(selected)}
                        />
                    )
                }
                tableName={translations.admin_users}
                columns={[
                    {
                        Header: 'ID',
                        accessor: 'id',
                        Cell: ({ value }) => <div>{value}</div>,
                    },
                    {
                        Header: translations.first_name,
                        accessor: 'first_name',
                        Cell: ({ value }) => <div>{value}</div>,
                    },
                    {
                        Header: translations.last_name,
                        accessor: 'last_name',
                        Cell: ({ value }) => <div>{value}</div>,
                    },
                    {
                        Header: translations.email,
                        accessor: 'email',
                        Cell: ({ value }) => <div>{value}</div>,
                    },
                    {
                        Header: '',
                        accessor: 'action',
                        Cell: ({ row }) => (
                            <div>
                                <Button
                                    onClick={() => {
                                        handleAdmin(row.cells[0].value);
                                    }}
                                    title={translations.show}
                                >
                                    <FaSearch />
                                </Button>
                                {/* {isNotDeletedAlready(row) ? (
                                    <Button
                                        onClick={() => {
                                            setAdminID(row.cells[0].value);
                                            setModal(true);
                                        }}
                                        title={translations.delete}
                                    >
                                        <FaTrash />
                                    </Button>
                                ) : (
                                    <Button onClick={() => store.reviveAdmin(row.cells[0].value)}>
                                        <FaUndoAlt />
                                    </Button>
                                )} */}
                            </div>
                        ),
                    },
                ]}
                data={store.admins.map(({ id, first_name, last_name, email, deleted_at }) => {
                    return {
                        id,
                        first_name,
                        last_name,
                        email,
                        deleted_at,
                    };
                })}
            />
            {/* {modal && (
                <Modal
                    title="Delete Admin"
                    ref={modalRef}
                    onClose={() => setModal(false)}
                    maxWidth="400px"
                >
                    <p style={{ color: '#333', marginBottom: '20px' }}>
                        {`Are you sure that you want to delete admin with id ${adminID}?`}
                    </p>
                    <div className="Modal-Controls">
                        <Button
                            label="Yes"
                            onClick={() => {
                                store.deleteAdmin(adminID);
                                setModal(false);
                            }}
                            style={{ width: '80px' }}
                        />
                        <Button
                            label="No"
                            onClick={() => setModal(false)}
                            style={{ width: '80px', marginLeft: '20px' }}
                        />
                    </div>
                </Modal>
            )} */}
        </>
    );
};

export default observer(Admins);
