const Event = ({ event }) => {
    const time = event.start.toLocaleTimeString('en', {
        timeStyle: 'short',
        hour12: false,
    });

    return (
        <div className={`Event ${event.isNext ? 'next' : ''}`}>
            <div className="Event-Text">
                {event.home_team_name} - {event.away_team_name}
            </div>
            <div className="Event-Imgs">
                <img src={event.home_team_img} alt="team one" />
                vs
                <img src={event.away_team_img} alt="team two" />
            </div>
            <div className="Event-Text">{time}</div>
            <div className="Event-Text">{event.location}</div>
        </div>
    );
};

export default Event;
