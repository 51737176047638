import translations from '../translations/de';

const seasons = [
    { value: '2025', label: '2024-25' },
    { value: '2024', label: '2023-24' },
    { value: '2023', label: '2022-23' },
];

const positions = [
    { value: null, label: translations.all },
    { value: 'Attacker', label: translations.striker },
    { value: 'Defence', label: translations.defender },
    { value: 'Goalie', label: translations.goalie },
];

const homeGames = [
    { value: 'home', label: translations.home_games },
    { value: 'away', label: translations.away_games },
    { value: 'home,away', label: translations.away_games + ' / ' + translations.home_games },
];

export { seasons, positions, homeGames };
