import React, { createContext, useContext, useState, useEffect } from 'react';
import API from '../api/seasons';

const SeasonsContext = createContext();

export const SeasonsProvider = ({ children }) => {
    const [seasons, setSeasons] = useState(null);
    const [currentSeason, setCurrentSeason] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchSeasons = async () => {
            try {
                const response = await API.getSeasons();
                let formatedSeasons = response?.data?.all_seasons.map((season) => {
                    let label = Number(season) - 1 + '-' + season.toString().slice(-2);

                    return { label: label, value: season };
                });
                setSeasons(formatedSeasons);
                setCurrentSeason(response?.data?.current_season);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchSeasons();
    }, []);
    return (
        <SeasonsContext.Provider value={{ seasons, currentSeason, loading, error }}>
            {children}
        </SeasonsContext.Provider>
    );
};

export const useSeasons = () => useContext(SeasonsContext);
