import React, { useEffect, useState } from 'react';
import APIMatches from '../../api/matches';
import ItemImage from '../../components/ItemImage';

const Lineup = ({ store }) => {
    const [data, setData] = useState(null);
    const [homeTeamID, setHomeTeamID] = useState(store?.selectedMatch?.home_team?.id ?? null);

    useEffect(() => {
        const getLineupData = async () => {
            try {
                const res = await APIMatches.getLineup(store.selectedMatch.id);
                setData(res);
            } catch (e) {
                console.log(e);
            }
        };

        store?.selectedMatch && getLineupData();
    }, [store]);

    const separatePlayersByTeam = (players) => {
        if (!players) return { team1Players: [], team2Players: [] };

        homeTeamID === null && setHomeTeamID(players[0]?.team_id);
        const team1Players = players.filter((player) => player?.team_id === homeTeamID);
        const team2Players = players.filter((player) => player?.team_id !== homeTeamID);

        return { team1Players, team2Players };
    };

    const getLeftPlayer = (player1, key) => {
        return (
            <div className="left-player" key={key}>
                <span className="id-span"># {player1?.jersey_number}</span>
                <span className="name">
                    {player1?.player?.first_name}
                    {` `}
                    {player1?.player?.last_name}
                </span>
                <ItemImage
                    imgSrc={player1?.player?.image_url}
                    alt="reward"
                    listView={true}
                    isAvatar={true}
                />
            </div>
        );
    };

    const getRightPlayer = (player2, key) => {
        return (
            <div className="right-player" key={key}>
                <ItemImage
                    imgSrc={player2?.player?.image_url}
                    alt="reward"
                    listView={true}
                    isAvatar={true}
                />
                <span className="name">
                    {player2?.player?.first_name}
                    {` `}
                    {player2?.player?.last_name}
                </span>
                <span className="id-span"># {player2?.jersey_number}</span>
            </div>
        );
    };

    const haveOnlyLeftPlayer = (player1, position) => {
        return (
            <div className="faceoff-wrapper">
                {getLeftPlayer(player1)}
                <span className="spec-span">{position}</span>
                <span className="position"></span>
            </div>
        );
    };

    const haveOnlyRightPlayer = (player2, position) => {
        return (
            <div className="faceoff-wrapper">
                <span className="spec-span">{position}</span>
                <span className="position" />
                {getRightPlayer(player2)}
            </div>
        );
    };

    const findLeftDefender = (team1, team2) => {
        const player1 = team1.find(
            (member) => member.position_lcr_name === 'L' && member.position_name === 'Verteidiger',
        );
        const player2 = team2.find(
            (member) => member.position_lcr_name === 'L' && member.position_name === 'Verteidiger',
        );

        if (player1 && player2) {
            return (
                <div className="faceoff-wrapper">
                    {getLeftPlayer(player1)} LV {getRightPlayer(player2)}
                </div>
            );
        } else if (player1) {
            return haveOnlyLeftPlayer(player1, 'LV');
        } else if (player2) {
            return haveOnlyRightPlayer(player2, 'LV');
        }
    };
    const findRightDefender = (team1, team2) => {
        const player1 = team1.find(
            (member) => member.position_lcr_name === 'R' && member.position_name === 'Verteidiger',
        );
        const player2 = team2.find(
            (member) => member.position_lcr_name === 'R' && member.position_name === 'Verteidiger',
        );

        if (player1 && player2) {
            return (
                <div className="faceoff-wrapper">
                    {getLeftPlayer(player1)} RV {getRightPlayer(player2)}
                </div>
            );
        } else if (player1) {
            return haveOnlyLeftPlayer(player1, 'RV');
        } else if (player2) {
            return haveOnlyRightPlayer(player2, 'RV');
        }
    };
    const findLeftAttacker = (team1, team2) => {
        const player1 = team1.find(
            (member) => member.position_lcr_name === 'L' && member.position_name === 'Stürmer',
        );
        const player2 = team2.find(
            (member) => member.position_lcr_name === 'L' && member.position_name === 'Stürmer',
        );

        if (player1 && player2) {
            return (
                <div className="faceoff-wrapper">
                    {getLeftPlayer(player1)} LF {getRightPlayer(player2)}
                </div>
            );
        } else if (player1) {
            haveOnlyLeftPlayer(player1, 'LF');
        } else if (player2) {
            return haveOnlyRightPlayer(player2, 'LF');
        }
    };

    const findRightAttacker = (team1, team2) => {
        const player1 = team1.find(
            (member) => member.position_lcr_name === 'R' && member.position_name === 'Stürmer',
        );
        const player2 = team2.find(
            (member) => member.position_lcr_name === 'R' && member.position_name === 'Stürmer',
        );

        if (player1 && player2) {
            return (
                <div className="faceoff-wrapper">
                    {getLeftPlayer(player1)} RF {getRightPlayer(player2)}
                </div>
            );
        } else if (player1) {
            return haveOnlyLeftPlayer(player1, 'RF');
        } else if (player2) {
            return haveOnlyRightPlayer(player2, 'RF');
        }
    };
    const findCenter = (team1, team2) => {
        const player1 = team1.find(
            (member) => member.position_lcr_name === 'C' && member.position_name === 'Stürmer',
        );
        const player2 = team2.find(
            (member) => member.position_lcr_name === 'C' && member.position_name === 'Stürmer',
        );

        if (player1 && player2) {
            return (
                <div className="faceoff-wrapper">
                    {getLeftPlayer(player1)} C {getRightPlayer(player2)}
                </div>
            );
        } else if (player1) {
            return haveOnlyLeftPlayer(player1, 'C');
        } else if (player2) {
            return haveOnlyRightPlayer(player2, 'C');
        }
    };

    const findGoalie = (team1, team2) => {
        const player1 = team1.find(
            (member) => member.position_lcr_name === '-' && member.position_name === 'Torhüter',
        );
        const player2 = team2.find(
            (member) => member.position_lcr_name === '-' && member.position_name === 'Torhüter',
        );

        if (player1 && player2) {
            return (
                <div className="faceoff-wrapper">
                    {getLeftPlayer(player1)} G {getRightPlayer(player2)}
                </div>
            );
        } else if (player1) {
            return haveOnlyLeftPlayer(player1, 'G');
        } else if (player2) {
            return haveOnlyRightPlayer(player2, 'G');
        }
    };

    const findHurtedPlayers = (team1, team2) => {
        const results = [];

        const maxLength = Math.max(team1.length, team2.length);

        var tempVar1 = [];
        var tempVar2 = [];
        for (let i = 0; i < maxLength; i++) {
            const player1 = team1[i] || {};
            const player2 = team2[i] || {};

            if (player1?.absence_acronym === 'IN' && player1?.absence_name === 'Krank') {
                let key = `IN-left_${i}`;
                tempVar1.push(getLeftPlayer(player1, key));
            }

            if (player2?.absence_acronym === 'IN' && player2?.absence_name === 'Krank') {
                let key = `IN-right_${i}`;
                tempVar2.push(getRightPlayer(player2, key));
            }
        }
        results.push(
            <React.Fragment key={`sick-players`}>
                <div className="left-team">{tempVar1}</div>
                {(tempVar1.length > 0 || tempVar2.length > 0) && <div className="position" />}
                <div className="right-team">{tempVar2}</div>
            </React.Fragment>,
        );

        return results;
    };
    const findSuplementaryPlayers = (team1, team2) => {
        const results = [];

        const maxLength = Math.max(team1.length, team2.length);
        var tempVar1 = [];
        var tempVar2 = [];
        for (let i = 0; i < maxLength; i++) {
            const player1 = team1[i] || {};
            const player2 = team2[i] || {};

            if (player1?.absence_acronym === 'SU' && player1?.absence_name === 'Überzählig') {
                let key = `SU-left_${i}`;
                tempVar1.push(getLeftPlayer(player1, key));
            }

            if (player2?.absence_acronym === 'SU' && player2?.absence_name === 'Überzählig') {
                let key = `SU-right_${i}`;
                tempVar2.push(getRightPlayer(player2, key));
            }
        }
        results.push(
            <React.Fragment key="absent-players">
                <div className="left-team">{tempVar1}</div>
                {(tempVar1.length > 0 || tempVar2.length > 0) && <div className="position" />}
                <div className="right-team">{tempVar2}</div>
            </React.Fragment>,
        );
        return results;
    };

    const findLockedPlayers = (team1, team2) => {
        const results = [];

        const maxLength = Math.max(team1.length, team2.length);
        var tempVar1 = [];
        var tempVar2 = [];
        for (let i = 0; i < maxLength; i++) {
            const player1 = team1[i] || {};
            const player2 = team2[i] || {};

            if (player1?.absence_acronym === 'BA' && player1?.absence_name === 'Gesperrt') {
                let key = `BL-left_${i}`;
                tempVar1.push(getLeftPlayer(player1, key));
            }

            if (player2?.absence_acronym === 'BA' && player2?.absence_name === 'Gesperrt') {
                let key = `BL-left_${i}`;
                tempVar2.push(getRightPlayer(player2, key));
            }
        }
        results.push(
            <React.Fragment key={`blocked-players`}>
                <div className="left-team">{tempVar1}</div>
                {(tempVar1.length > 0 || tempVar2.length > 0) && <div className="position" />}
                <div className="right-team">{tempVar2}</div>
            </React.Fragment>,
        );
        return results;
    };

    const getSectionTitle = (index) => {
        if (index === 0) {
            return 'Starting Six';
        } else if (index < 4) {
            return `${index}. Block`;
        } else if (Object.keys(data.data)[index] === 'backup_attackers') {
            return 'Stürmer';
        } else if (Object.keys(data.data)[index] === 'backup_goalkeepers') {
            return 'Backup';
        }
        return '';
    };

    return (
        <div className="lineup-wrapper">
            {data?.data &&
                Object.values(data.data).map((lineup, index) => {
                    if (Object.keys(data.data)[index] === 'absentees') {
                        return null;
                    }
                    let { team1Players, team2Players } = separatePlayersByTeam(lineup);
                    return (
                        <React.Fragment key={`fragmentID-${index}`}>
                            {index === 0 && (
                                <div key={`teamLogos-${index}`} className="team-logos">
                                    <ItemImage
                                        imgSrc={team1Players[0]?.team?.image_url}
                                        listView={true}
                                    />
                                    VS.
                                    <ItemImage
                                        imgSrc={team2Players[0]?.team?.image_url}
                                        listView={true}
                                    />
                                </div>
                            )}

                            <h2 key={`lineTitle-${index}`} className="line-title">
                                <span className="line" />
                                {getSectionTitle(index)}
                                <span className="line" />
                            </h2>
                            <div key={`G-${index}`}>
                                {findGoalie(team1Players, team2Players, index + 1)}
                            </div>
                            <div key={`LV-${index}`}>
                                {findLeftDefender(team1Players, team2Players, index + 1)}
                            </div>
                            <div key={`RV-${index}`}>
                                {findRightDefender(team1Players, team2Players, index + 1)}
                            </div>
                            <div key={`LF-${index}`}>
                                {findLeftAttacker(team1Players, team2Players, index + 1)}
                            </div>
                            <div key={`C-${index}`}>
                                {findCenter(team1Players, team2Players, index + 1)}
                            </div>
                            <div key={`RF-${index}`}>
                                {findRightAttacker(team1Players, team2Players, index + 1)}
                            </div>
                        </React.Fragment>
                    );
                })}
            {data?.data &&
                Object.values(data.data).map((lineup, index) => {
                    const { team1Players, team2Players } = separatePlayersByTeam(lineup);
                    if (Object.keys(data.data)[index] === 'absentees') {
                        return (
                            <React.Fragment key={`fragment2ID-${index}`}>
                                <h2 className="line-title">
                                    <span className="line" />
                                    {`Überzählig`}
                                    <span className="line" />
                                </h2>
                                <div key={`SU-${index}`} className="player-wrapper">
                                    {findSuplementaryPlayers(team1Players, team2Players)}
                                </div>
                                <h2 className="line-title">
                                    <span className="line" />
                                    {`Krank`}
                                    <span className="line" />
                                </h2>
                                <div key={`IN-${index}`} className="player-wrapper">
                                    {findHurtedPlayers(team1Players, team2Players)}
                                </div>
                                <h2 className="line-title">
                                    <span className="line" />
                                    {`Gesperrt`}
                                    <span className="line" />
                                </h2>
                                <div key={`BL-${index}`} className="player-wrapper">
                                    {findLockedPlayers(team1Players, team2Players)}
                                </div>
                            </React.Fragment>
                        );
                    } else {
                        return null;
                    }
                })}
        </div>
    );
};

export default Lineup;
