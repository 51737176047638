import './Dropdown.scss';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import { useState, useRef, useEffect } from 'react';

export default function Dropdown({ label = '', options = [], onOptionClick, disabled = false }) {
    const [showDropdown, setShowDropdown] = useState(false);
    const menuRef = useRef(null);

    useEffect(() => {
        const handleClickOutsideMenu = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutsideMenu);
        return () => document.removeEventListener('mousedown', handleClickOutsideMenu);
    }, [menuRef]);

    return (
        <div
            className={`Dropdown ${showDropdown ? 'show' : ''} ${disabled ? 'disabled' : ''}`}
            onClick={() => !disabled && setShowDropdown(!showDropdown)}
            ref={menuRef}
        >
            <div className="Dropdown-Label">
                <span>{label}</span>
                {showDropdown ? <FaAngleUp /> : <FaAngleDown />}
            </div>
            <div className="Dropdown-Menu">
                {options.map((option, i) => (
                    <div
                        className={`option ${option.disabled ? 'disabled' : ''}`}
                        key={i}
                        onClick={() => !option.disabled && onOptionClick(option.value)}
                        title={option.title}
                    >
                        <span className="icon">{option.icon && option.icon()}</span>
                        <span className="name">{option.name}</span>
                    </div>
                ))}
            </div>
        </div>
    );
}
