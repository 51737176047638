import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Item from '../../components/Item';
import { useParams } from 'react-router-dom';
import getPlayerFormInput from './getPlayerFormInput';
import clearObjProps from '../../helpers/clearObjProps';
import ItemImage from '../../components/ItemImage';
import translations from '../../translations/de';
import APITeams from '../../api/teams';
import userPlaceholder from './../../assets/images/user_placeholder.png';

const Player = ({ store }) => {
    const { team_id, player_id } = useParams();
    const [resetProp, setResetProp] = useState(null);
    const [edit, setEdit] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [teams, setTeams] = useState([]);
    const { league_id } = useParams();

    useEffect(() => {
        store.getPlayer(team_id, player_id);
        APITeams.getTeams({ params: { league_id } })
            .then((res) => setTeams(res.data.items))
            .catch((err) => console.log(err));
    }, [store, team_id, player_id, league_id]);

    const playerModel = clearObjProps({ ...store.selectedPlayer }, [
        'id',
        'licence_nr',
        'deleted_at',
    ]);

    const onSubmitHandler = (e) => {
        error && setError(null);
        setLoading(true);
        let nowDate = new Date(e.birthday);
        let changedDate = new Date(nowDate.setHours(6));

        store
            .editPlayer({
                ...e,
                birthday: changedDate,
            })
            .then(() => {
                setEdit(false);
                setLoading(false);
            })
            .catch((err) => {
                setError(err);
                setLoading(false);
            });
    };

    return store.selectedPlayer && store.selectedTeam ? (
        <Item
            links={[
                {
                    path: `/${league_id}/teams`,
                    name: translations.teams,
                    onThisLinkClick: () => {
                        store.selectPlayer(undefined);
                    },
                },
                {
                    path: `/${league_id}/teams/${store.selectedTeam.id}`,
                    name: store.selectedTeam.name,
                },
            ]}
            name={store.selectedPlayer.first_name + ' ' + store.selectedPlayer.last_name}
            id={`[ player_ID: ${store.selectedPlayer.id} ]`}
            infoField={`[ Licence_Nr: ${store.selectedPlayer.licence_nr} ]`}
            itemModel={playerModel}
            edit={edit}
            onSubmit={onSubmitHandler}
            onEdit={() => setEdit(true)}
            onCancel={() => {
                error && setError(null);
                setEdit(false);
            }}
            error={error}
            loading={loading}
            determineInputType={({ value, name, register, error, disabled, index, control }) =>
                getPlayerFormInput({
                    value,
                    name,
                    register,
                    error,
                    disabled,
                    index,
                    resetValue: (name, value) => setResetProp({ [name]: value }),
                    control,
                    team_acronym: store.selectedTeam.acronym,
                    teams,
                })
            }
            resetProp={resetProp}
        >
            <ItemImage imgSrc={store.selectedPlayer.image_url ?? userPlaceholder} isAvatar />
        </Item>
    ) : null;
};

export default observer(Player);
