export const womenLeagues = [
    { id: 325, name: 'PFWL' },
    { id: 344, name: 'SWHL B' },
    { id: 408057, name: 'SWHL C' },
    { id: 408282, name: 'SWHL D' },
    { id: 0, name: 'Andere Teams' },
];

export const womenLeaguesOptions = [
    { value: 325, label: 'PFWL' },
    { value: 344, label: 'SWHL B' },
    { value: 408057, label: 'SWHL C' },
    { value: 408282, label: 'SWHL D' },
    { value: 0, label: 'Andere Teams' },
];
