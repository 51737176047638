import api from './apiLibrary';

const API = {
    getContacts(params) {
        return api.get('/api/admin/contact', params);
    },
    getContact(id, params) {
        return api.get(`/api/admin/contact/${id}`, params);
    },
    createContact(data) {
        return api.post('/api/admin/contact', data);
    },
    editContact(id, data) {
        return api.put(`/api/admin/contact/${id}`, data);
    },
    deleteContact(id) {
        return api.delete(`/api/admin/contact/${id}`);
    },
};

export default API;
