export const phases = [
    {
        id: 327073,
        external_id: 4331,
        name: 'National Cup Women Runde 1',
        acronym: '1. Runde',
        sort_order: 'S1',
    },
    {
        id: 327074,
        external_id: 4333,
        name: 'National Cup Women Runde 2',
        acronym: '2. Runde',
        sort_order: 'S2',
    },
    {
        id: 327075,
        external_id: 4353,
        name: 'National Cup Women 1/32 Final',
        acronym: '1/32-Final',
        sort_order: 'S3',
    },
    {
        id: 327076,
        external_id: 4354,
        name: 'National Cup Women 1/16 Final',
        acronym: '1/16-Final',
        sort_order: 'S4',
    },
    {
        id: 331591,
        external_id: 4363,
        name: 'National Cup Women 1/8 Final',
        acronym: '1/8-Final',
        sort_order: 'S5',
    },
    {
        id: 348120,
        external_id: 4428,
        name: 'National Cup Women 1/4 Final',
        acronym: '1/4-Final',
        sort_order: 'S6',
    },
    {
        id: 355052,
        external_id: 4448,
        name: 'National Cup Women 1/2 Final',
        acronym: '1/2-Final',
        sort_order: 'S6',
    },
    {
        id: 357640,
        external_id: 4441,
        name: 'National Cup Final',
        acronym: 'Final',
        sort_order: 'S5',
    },
];
