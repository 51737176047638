import { useEffect, useState } from 'react';
import Page from './../../components/Page';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import './calendar.scss';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Event from './Event';
import { useHistory } from 'react-router-dom';
import Select from '../../components/Select';
import leagues from '../../constants/leagues';
import Placeholder from './../../assets/images/Placeholder.png';
const localizer = momentLocalizer(moment);

const Home = ({ matchesStore, calendarStore, adminStore }) => {
    const hasRoleAdmin = adminStore.roles.find((role) => role === 'ADMIN');
    const nextMatchId = matchesStore.findNextMatch();
    const [league, setLeague] = useState(1);

    useEffect(() => {
        matchesStore.getHomeMatches(league);
    }, [matchesStore, league]);

    const history = useHistory();
    const myEventsList = matchesStore.matches.map((event) => {
        return (
            event.begin_date && {
                start: new Date(event.begin_date),
                end: new Date(event.end_date),
                allDay: false,
                home_team_name: event.home_team.name,
                home_team_img: event.home_team.image_url ?? Placeholder,
                away_team_name: event.away_team.name,
                away_team_img: event.away_team.image_url ?? Placeholder,
                location: event.location,
                id: event.id,
                isNext: nextMatchId === event.id ? true : false,
            }
        );
    });

    return (
        <Page>
            <div className="home-wrapper">
                <div className="league-dropdown">
                    <Select
                        hasError={false}
                        wider_field={true}
                        label="league"
                        options={leagues}
                        defaultValue={leagues[0]}
                        onChange={(e) => {
                            setLeague(e.value);
                        }}
                    />
                </div>
                <div className="calendar-wrapper">
                    <Calendar
                        localizer={localizer}
                        events={myEventsList}
                        startAccessor="start"
                        endAccessor="end"
                        views={['month']}
                        components={{
                            event: Event,
                        }}
                        onSelectEvent={({ id }) => {
                            hasRoleAdmin && matchesStore.selectMatch(id);
                            hasRoleAdmin &&
                                history.push(`/${league}/matches/${matchesStore.selectedMatch.id}`);
                        }}
                        onNavigate={(date) => calendarStore.selectDate(date)}
                        //date={calendarStore.selectedDate}
                    />
                </div>
            </div>
        </Page>
    );
};

export default observer(Home);
