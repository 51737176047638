import api from './apiLibrary';

const API = {
    getPlayerStatsPerGame(params) {
        return api.get('/api/player-stats-per-game', params);
    },
    getGoalieStatsPerSeason(params) {
        return api.get('/api/goalie-stats-per-season', params);
    },
    getPlayerStatsPerSeason(params) {
        return api.get('/api/player-stats-per-season', params);
    },
    getTeamStatsPerGame(params) {
        return api.get('/api/team-stats-per-game', params);
    },
    getTeamStatsPerSeason(params) {
        return api.get('/api/team-stats-per-season', params);
    },
};

export default API;
