import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Table from '../../components/Table';
import translations from '../../translations/de';
import ItemImage from '../../components/ItemImage';
import Button from '../../components/Button';
import { FaSearch /* FaUndoAlt, FaTrash*/ } from 'react-icons/fa';
//import Modal from '../../components/Modal';
//import API from '../../api/teams';
//import useModal from '../../customHooks/useModal';
import Loader from '../../components/Loader';
import { useHistory } from 'react-router-dom';

const Players = ({ store }) => {
    const { team_id } = useParams();
    const history = useHistory();
    //const [playerID, setPlayerID] = useState(null);
    //const modalRef = useRef(null);
    //const [modal, setModal] = useModal(false, modalRef);
    const [loading, setLoading] = useState(false);
    const { league_id } = useParams();

    const handlePlayer = (id) => {
        history.push(`/${league_id}/teams/${store.selectedTeam.id}/players/${id}`);
    };

    // const isNotDeletedAlready = (row) => {
    //     return !row.original.deleted_at;
    // };

    // const deletePlayer = () => {
    //     try {
    //         API.deletePlayer(playerID).then(() => {
    //             var temp = [...store?.players];
    //             temp.forEach((member) => {
    //                 if (member.id === playerID) {
    //                     store?.selectedTeam?.editPlayer({ ...member, deleted_at: 'deleted' });
    //                 }
    //             });
    //         });
    //     } catch (e) {
    //         console.log(e);
    //     }
    // };

    // const revivePlayer = (id) => {
    //     try {
    //         API.revivePlayer(id).then(() => {
    //             store.players.forEach((element) => {
    //                 if (element.id === id) {
    //                     store?.selectedTeam?.editPlayer({
    //                         ...element,
    //                         deleted_at: null,
    //                     });
    //                 }
    //             });
    //         });
    //     } catch (e) {
    //         console.log(e);
    //     }
    // };

    useEffect(() => {
        setLoading(true);
        store.getPlayers(team_id).then(() => setLoading(false));
    }, [store, team_id]);

    return (
        <div>
            {!loading ? (
                <Table
                    columns={[
                        {
                            Header: 'ID',
                            accessor: 'id',
                            Cell: ({ value, row }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.avatar,
                            accessor: 'image_url',
                            Cell: ({ value, row }) => (
                                <div>
                                    <ItemImage imgSrc={value} isAvatar={true} listView={true} />
                                </div>
                            ),
                        },
                        {
                            Header: translations.first_name,
                            accessor: 'first_name',
                            Cell: ({ value, row }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.last_name,
                            accessor: 'last_name',
                            Cell: ({ value, row }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.postion,
                            accessor: 'position',
                            Cell: ({ value, row }) => <div>{value}</div>,
                        },
                        {
                            Header: translations.number,
                            accessor: 'default_jersey_number',
                            Cell: ({ value, row }) => <div>{value}</div>,
                        },
                        {
                            Header: '',
                            accessor: 'action',
                            Cell: ({ row }) => (
                                <div>
                                    <Button
                                        onClick={() => {
                                            handlePlayer(row.cells[0].value);
                                        }}
                                        title={translations.show}
                                    >
                                        <FaSearch />
                                    </Button>
                                    {/* {isNotDeletedAlready(row) ? (
                                        <Button
                                            title={translations.delete}
                                            onClick={() => {
                                                setPlayerID(row.cells[0].value);
                                                setModal(true);
                                            }}
                                        >
                                            <FaTrash />
                                        </Button>
                                    ) : (
                                        <Button onClick={() => revivePlayer(row.cells[0].value)}>
                                            <FaUndoAlt />
                                        </Button>
                                    )} */}
                                </div>
                            ),
                        },
                    ]}
                    data={store?.players.map(
                        ({
                            id,
                            image_url,
                            first_name,
                            last_name,
                            position,
                            default_jersey_number,
                            deleted_at,
                        }) => {
                            return {
                                id,
                                image_url,
                                first_name,
                                last_name,
                                position,
                                default_jersey_number,
                                deleted_at,
                            };
                        },
                    )}
                />
            ) : (
                <Loader size="big" theme="dark" />
            )}
            {/* {modal && (
                <Modal
                    title="Delete Player"
                    ref={modalRef}
                    onClose={() => setModal(false)}
                    maxWidth="400px"
                >
                    <p style={{ color: '#333', marginBottom: '20px' }}>
                        {`Are you sure that you want to delete player with id ${playerID}?`}
                    </p>
                    <div className="Modal-Controls">
                        <Button
                            label="Yes"
                            onClick={() => {
                                //deletePlayer();
                                setModal(false);
                            }}
                            style={{ width: '80px' }}
                        />
                        <Button
                            label="No"
                            onClick={() => setModal(false)}
                            style={{ width: '80px', marginLeft: '20px' }}
                        />
                    </div>
                </Modal>
            )} */}
        </div>
    );
};

export default Players;
