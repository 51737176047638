import { types, flow } from 'mobx-state-tree';
import Season from './Season';
import API from '../../api/seasons';
import APIGames from '../../api/games';
import requsetErrorMessage from '../../helpers/requsetErrorMessage';

const Seasons = types
    .model('Seasons', {
        seasons: types.array(Season),
        selectedSeason: types.maybe(types.reference(Season)),
        // selectedGame: types.maybe(types.reference(Game)),
        error: types.union(
            types.optional(types.string, ''),
            types.optional(types.array(types.string), []),
        ),
        dataLoaded: types.optional(types.boolean, false),
    })
    .actions((self) => ({
        getSeasons: flow(function* getSeasons() {
            self.error = '';
            try {
                const res = yield API.getSeasons();
                self.dataLoaded = true;
                self.seasons = res.data;
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        selectSeason(id) {
            self.selectedSeason = id;
        },
        getSeason: flow(function* getSeason(id) {
            self.error = '';
            try {
                const res = yield API.getSeason(id);
                self.dataLoaded = true;
                self.seasons = [];
                self.seasons.push(res.data);
                self.selectedSeason = res.data.id;
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        editSeason: flow(function* editSeason(edited) {
            try {
                const res = yield API.editSeason(self.selectedSeason.id, edited);
                self.selectedSeason.editSeason(res.data);
            } catch (error) {
                throw error;
            }
        }),
        addSeason: flow(function* addSeason(newSeason) {
            try {
                const res = yield API.createSeason(newSeason);
                self.seasons.push(res.data);
            } catch (error) {
                throw error;
            }
        }),
        deleteSeason: flow(function* deleteSeason(id) {
            try {
                yield API.deleteSeason(id);
                const index = self.seasons.findIndex((s) => s.id === id);
                self.seasons.splice(index, 1);
            } catch (error) {
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        selectGame(id) {
            self.selectedGame = id;
        },
        getGame: flow(function* getGame(season_id, game_id) {
            self.error = '';
            try {
                if (!self.selectedGame) {
                    const season = yield API.getSeason(season_id);
                    if (!self.selectedSeason) {
                        self.seasons = [];
                        self.seasons.push(season.data);
                        self.selectedSeason = season.data.id;
                    }
                    const game = yield APIGames.getGame(game_id);
                    self.selectedGame = game.data.fan_game_details.id;
                    self.selectedGame.rewards = game.data.fan_game_details.rewards;
                    self.selectedGame.questions = game.data.fan_game_details.questions;
                } else {
                    self.selectedGame.getGameDetails(game_id);
                }
                self.dataLoaded = true;
            } catch (error) {
                self.dataLoaded = true;
                self.error = requsetErrorMessage(error && error.message ? error.message : error);
            }
        }),
        addGame: flow(function* addGame(newGame) {
            try {
                const res = yield APIGames.createSeasonalMoodGame(self.selectedSeason.id, newGame);
                self.selectedSeason.fan_games.push(res.data);
            } catch (error) {
                throw error;
            }
        }),
        setDataLoaded() {
            self.dataLoaded = true;
        },
    }));

export default Seasons;
