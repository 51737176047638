import React, { forwardRef } from 'react';
import { FaTimes } from 'react-icons/fa';
import './Modal.scss';

const Modal = forwardRef(
    ({ title = '', children, onClose, maxWidth = '800px', disabled = false }, ref) => {
        return (
            <div className="Modal-Wrapper">
                <div className="Modal" ref={ref} style={{ maxWidth: maxWidth }}>
                    <div className="Modal-Header">
                        <p>{title}</p>
                        <button className="btn-close" disabled={disabled} onClick={onClose}>
                            <FaTimes />
                        </button>
                    </div>
                    <div className="Modal-Content">{children}</div>
                </div>
            </div>
        );
    },
);

export default Modal;
